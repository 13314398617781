import React, { useState } from "react";
import { Link } from "react-router-dom";
import noImage from "../../assets/images/no-image.jpg"
import {postOrderReturn } from "../../helper/backend-methods";
import T from "../../locale/translations-main";
function OrderProductDetails({ item, language, status , returnReason , orderId ,setUpdate,update}) {
    const [wrapPopUp, setWrapPopUP] = useState(false);
    const [returnPopUp, setReturnPopUp] = useState(false);
    const [selectedReason, setSelectedReason] = useState(0)
    const [selectedQty, setSelectedQty] = useState(0)
    const [errors, setErrors] = useState({ reason: "", returnQuantity: "" })
    const [apiLoading, setApiLoading] = useState(false)
    const [conditionChecked, setConditionChecked] = useState(false);

    const validateSubmit = () => {
        const errors = { reason: "", returnQuantity: "" };
        let flag = false;
        if (!selectedReason) {
            errors.reason = <T>You must select a reason to return your order.</T>;
            flag = true
        }
        if (!selectedQty) {
            errors.returnQuantity = <T>Please select a valid quantity.</T>;
            flag = true
        }
        setErrors(errors);
        if(!flag){
            setApiLoading(true)
            postOrderReturn({"orderId":orderId ,"orderItemId":item.OrderItemId ,"ReasonId" : selectedReason, "quantity": selectedQty}).then(({data})=>{
                if(data.Status){
                    setUpdate(update+1)
                    window.scrollTo({top: 0,left: 0,behavior: "smooth"});
                    setReturnPopUp(false)
                }
                else{
                    const errors = { reason: "", returnQuantity: "" };
                    errors.reason = <T>Your order could not be returned at the moment.</T>;
                    setErrors(errors);
                    setReturnPopUp(false)
                }
            }).catch((e)=>{
                console.log(e)
                const errors = { reason: "", returnQuantity: "" };
                errors.reason = <T>Your order could not be returned at the moment.</T>;
                setErrors(errors);
                setReturnPopUp(false)
            })
        }
    }

    return (
        <React.Fragment>
        <div className="order-details-container order-product-container d-flex justify-between flex-wrap position-relative">
            {
                item.IsReturntable ? 
                <T tagName={'div'} className="product-returnable-tag bg-primary">This item is Returnable</T> :
                <T tagName={'div'} className="product-returnable-tag bg-secondary">This item is Not Returnable</T>
            }
            <div className="order-card">
                <div className="order-card-wrapper d-flex align-items-center justify-between flex-wrap position-relative">
                    <div className="card-image">
                        <Link to={`/product/${item.ProductId}?title=${encodeURIComponent(item.ProductName).replaceAll('%20', '+')}`} className="product-image">
                            <img src={item.ProductPicture ? item.ProductPicture : noImage} alt={item.ProductName} draggable={false}  onError={(e) => { e.target.onerror = null; e.target.src = noImage }}/>
                        </Link>
                    </div>
                    <div className="card-title-brand d-inline-flex flex-column">
                        <Link to={`/product/${item.ProductId}?title=${encodeURIComponent(item.ProductName).replaceAll('%20', '+')}`} className="product-title decoration-none text-gray-500 fs-16 fw-500" title={item.ProductName}>{item.ProductName.length > 45 ? item.ProductName.slice(0, 42) + "..." : item.ProductName}</Link>
                        {
                            item.GiftCard ?
                                <>
                                    <div className="wrap-details">
                                        <button onClick={() => setWrapPopUP(true)} className="fs-14 link fw-400 d-flex align-items-center"><i className="ri-gift-line mr-2 fs-18"></i><T>Wrap Details</T></button>
                                    </div>
                                    <div className={wrapPopUp ? "wrap-card-popup active" : "wrap-card-popup"}>
                                        <div className="popup-wrapper">
                                            {
                                                item.GiftCardDetails.GiftWrappingImage ?
                                                    <>  <T className="popup-title" tagName={'h2'}>Product has a Gift Wrap</T>
                                                        <div className="popup-image-container">
                                                            <img src={item.GiftCardDetails.GiftWrappingImage} alt="" />
                                                        </div>
                                                    </> :
                                                    <></>
                                            }
                                            {
                                                item.GiftCardDetails.HasCard ?
                                                    <>
                                                        <h2 className="fs-16 fw-500 text-gray-500"><T>Gift card attached</T></h2>
                                                        <h2 className="fs-14 fw-400 text-primary"><T>To: </T><span className="text-gray-400">{item.GiftCardDetails.To}</span></h2>
                                                        <h2 className="fs-14 fw-400 text-primary"><T>From: </T><span className="text-gray-400">{item.GiftCardDetails.From}</span></h2>
                                                        <h2 className="fs-14 fw-400 text-primary"><T>Message: </T><span className="text-gray-400">{item.GiftCardDetails.Message}</span></h2>
                                                    </> : <></>
                                            }
                                            <button className="btn btn-outline-secondary" onClick={() => { setWrapPopUP(false) }}><T>Close</T></button>
                                        </div>
                                    </div>
                                </>
                                : <></>
                        }
                    </div>
                    <div className="card-price d-inline-flex flex-column">
                        <span className="shipment-price-label fs-12 mb-1 text-gray-400 fw-400"><T>Price</T></span>
                        <div className="product-price d-inline-flex align-items-center">
                            <span className="product-currency text-gray-500 fs-16 fw-500">SAR</span>
                            <span className="product-amount text-secondary fs-16 fw-500 ml-1">{item.Discount ? (item.Price-(item.Discount / item.Quantity)).toFixed(2) : item.Price.toFixed(2)}</span>
                        </div>
                        { item.Discount ? <span className="product-amount text-gray-400 fs-14 fw-400"><s>SAR { (item.Price).toFixed(2) }</s></span> : <></> }
                    </div>
                    <div className="card-quantity">
                        <span className="fs-12 mb-1 text-gray-400 fw-400 d-block"><T>Quantity</T></span>
                        <span className="product-quanity fs-16 fw-500 text-gray-500">{item.Quantity} {item.Quantity > 1 ? <T>Items</T> : <T>Item</T>}</span>
                    </div>
                    <div className="total-price text-right position-relative">
                        <span className="fs-12 mb-1 text-gray-400 fw-400 d-block mb-1"><T>Sub Total</T></span>
                        <span className="product-currency text-gray-500 fs-16 fw-500 d-block">SAR <span className="fs-16 text-secondary">{item.Total.toFixed(2)}</span></span>
                        {item.Discount ? <span className="order-card-discount"><T>Saved:</T> SAR {item.Discount.toFixed(2)}</span> : <></>}
                    </div>
                    {
                        // item.IsReturntable && item.Status !== "Pending" ?
                        //     <div className="order-product-footer w-100 d-flex align-items-center justify-between">
                        //         <div className="order-product-reason">
                        //             {
                        //                 item.ReturnReason && ( item.ReturnStatus === "Requested" || item.ReturnStatus === "Rejected") ?
                        //                 <p className="m-0 text-gray-500 fw-400 fs-14"><strong className="fw-500">{ item.ReturnStatus === "Rejected" ? translations.MyOrders[language].returnDeclineReason : translations.MyOrders[language].returnReason}</strong> {item.ReturnReason}</p> : <></>
                        //             }
                        //             {
                        //                 item.ReturnDeliveryDate && item.ReturnStatus === "Approved" ?
                        //                 <p className="m-0 text-gray-500 fw-400 fs-14"><strong className="fw-500">{translations.MyOrders[language].returnDeliveryDate}</strong> {item.ReturnDeliveryDate}</p> : <></>
                        //             }
                        //         </div>
                        //         <div className="order-product-status">
                        //             {
                        //                 item.ReturnStatus ?
                        //                 <p className={`return-status ${item.ReturnStatus === "Requested" || item.ReturnStatus === "Approved" ? "text-secondary" : "text-primary"} fs-14 fw-500 m-0 p-0 d-inline-flex align-items-center`}>{ item.ReturnStatus === "Requested" ? <><i className="ri-checkbox-circle-line fs-18 mr-1"></i>{translations.MyOrders[language].returnRequested}</> : item.ReturnStatus === "Approved" ? translations.MyOrders[language].returnApproved : <><i className="ri-error-warning-line fs-18 mr-1"></i>{translations.MyOrders[language].returnDeclined}</> }</p>
                        //                 : <button onClick={() => { setReturnPopUp(true) }} className="btn btn-primary return-button">{translations.MyOrders[language].returnBtn}</button>
                        //             }
                        //         </div>
                        //     </div>

                        // : <></>
                    } 
                    {
                        // returnPopUp ?
                        // <section className="order-popup">
                        //     <div className="order-popup-overlay" onClick={()=>{setReturnPopUp(false); setSelectedReason(0); setConditionChecked(false)}}></div>
                        //     <div className="order-popup-wrapper">
                        //         <span className="popup-close" onClick={()=>{setReturnPopUp(false); setSelectedReason(0); setConditionChecked(false)}}><i className="ri-close-line"></i></span>
                        //         <h4 className="popup-heading">{translations.MyOrders[language].reasonforOrderReturn}</h4>
                        //         <label htmlFor="returnQuantity" className="fw-500 text-gray-500 fs-14 mt-0 mb-1 d-inline-block">{translations.MyOrders[language].returnQty}<span className="text-primary">*</span></label>
                        //         <input name="returnQuantity" max={item.RemainingQuantity} type="number" id="returnQuantity" value={selectedQty} onChange={(e)=>{ e.target.value.replace(/[^0-9.]/g, '').length ? setSelectedQty(parseInt(e.target.value.replace(/[^0-9.]/g, ''))) : setSelectedQty(0) }} className="return-quantity" placeholder="Enter Quantity"/>
                        //         { errors.returnQuantity ? <span className="text-primary fs-14 fw-400 w-100 text-center d-inline-block mb-4">{errors.returnQuantity}</span> : null }
                        //         <ul className="popup-reasons">
                        //         {
                        //                 returnReason.map((r,index)=>{
                        //                     return <li key={index} className="single-reason">
                        //                         <input onChange={(e)=>{ if(e.target.checked){ setSelectedReason(e.target.value) }}} checked={ r.Id == selectedReason } id={"reason"+index} name="cancelReason" type={"radio"} className="d-none" value={r.Id}/>
                        //                         <label htmlFor={"reason"+index}>{r.Reason}</label>
                        //                     </li>
                        //                 })
                        //             } 
                        //         </ul>
                        //         <div className="checkout-consent text-center d-flex align-items-center mt-0 mb-3">
                        //             <input name="checkoutConsent" id="checkoutConsent" className="input-checkbox" type={"checkbox"} checked={conditionChecked} onChange={(e)=>{setConditionChecked(e.target.checked)}}/><label htmlFor="checkoutConsent" className="fs-14 fw-400 text-gray-500"><span>{translations.MyOrders[language].agree}<Link to={"/return-policy"} className="text-primary decoration-none mx-1">{translations.MyOrders[language].refund}</Link></span></label>
                        //         </div>
                        //         { errors.reason ? <span className="text-primary fs-14 fw-400 w-100 text-center d-inline-block mb-4">{errors.reason}</span> : null }
                        //         <div className="d-flex align-items-center popup-footer">
                        //             <button onClick={()=>{setReturnPopUp(false); setSelectedReason(0); setConditionChecked(false)}} type="button" className="btn btn-outline-secondary">{translations.MyOrders[language].closeBtn}</button>
                        //             {   
                        //                 conditionChecked && selectedReason !== 0 && selectedQty !== 0 && selectedQty <= item.RemainingQuantity ? 
                        //                 <button onClick={validateSubmit} disabled={apiLoading} type="button"className= "btn btn-primary ml-2">{apiLoading ? translations.MyOrders[language].pleaseWait : translations.MyOrders[language].confirm}</button>:
                        //                 <button type="button" disabled={true} className= "btn btn-disabled ml-2">{translations.MyOrders[language].confirm}</button>
                        //             }
                        //         </div> 
                        //     </div>
                        // </section> : <></>
                    }
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default OrderProductDetails;
import { useState, useEffect, useRef, useContext } from "react";
import { getProducts, getFilters } from "../../helper/backend-methods";
import { useSearchParams } from "react-router-dom";
import { analyticsPageEvent, analyticsTrackEvent } from "../../components/Analytics";
import Skeleton from 'react-loading-skeleton'
import ScrollContainer from "react-indiana-drag-scroll";
import BreadCrumbs from "../../components/BreadCrumbs";
import coverImage from "../../assets/images/product-list-cover.png"
import ProductCard from "./product-card";
import T from "../../locale/translations-main";
import "../../assets/css/--component-product-list.css"
import 'react-loading-skeleton/dist/skeleton.css'
import MultiRangeSlider from "multi-range-slider-react";
import { GlobalContext } from "../../context";
import ProductCardAlgolia from "./product-card-algolia";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Products = (props) => {
    const { searchResults ,firstHit ,updateFirstHit } = useContext(GlobalContext);
    const [search, setSearch] = useSearchParams();
    const [ products, setProducts ] = useState([]);
    const [ totalProducts, setTotalProducts ] = useState(0);
    const [sortBy, setSortBy] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sidebarLoading, setSidebarLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [refresh, setRefresh] = useState(0)
    const [showSidebar, setShowSidebar] = useState(false)
    const [expandChildCategories, setExpandChildCategories] = useState(false)
    const [expandBrands, setExpandBrands] = useState(false)
    const [filters, setFilters] = useState(null)
    const [offset, setOffset] = useState(0)
    const loader = useRef(false);
    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Products", link: "" },
    ];

    useEffect(() => {
        initializeListener();
    }, [])

    useEffect(() => {
        return () => {
            updateFirstHit(false);
        }
    }, [])

    useEffect(() => {
        const { category, brand, tag, gender, keyword } = getAdditionalParams();
        fetchProducts(gender, category, null, tag, brand, null, null, keyword);
        fetchFilters(gender, category, tag, brand, keyword);
    }, [search])

    useEffect(()=>{
        if(refresh){
            updateFirstHit(false);
            setBtnLoading(true);
            setOffset(0);
            const { category, subcategory, brand , special, gender, keyword, age, rating, PriceMax, PriceMin } = getActiveFilters();
            fetchProducts(gender, category, subcategory, special, brand, age, rating, keyword, PriceMax, PriceMin, sortBy, 12, 0)
        }
    },[refresh])

    useEffect(()=>{
        if(offset){
            setBtnLoading(true);
            const { category, subcategory, brand , special, gender, keyword, age, rating, PriceMax, PriceMin } = getActiveFilters();
            fetchProducts(gender, category, subcategory, special, brand, age, rating, keyword, PriceMax, PriceMin, sortBy, 12, offset)
        }
    },[offset])

    const fetchProducts = (gender = null, category = null, subcategory = null, tag = null, brand = null, age = null, rating = null, keyword = "", priceMax = 9999, priceMin = 0, sort = 0, limit = 12, currentOffset = 0) => {
        getProducts({
            ...(gender && gender.length && { Gender: gender }),
            ...(category && category.length && { Category: category }),
            ...(subcategory && subcategory.length && { subCategory: subcategory }),
            ...(tag && { special: tag }),
            ...(brand && brand.length && { Brand: brand }),
            ...(age && age.length && { Age: age }),
            ...(rating && { Rating: rating }),
            ...(keyword && keyword.length && { Keyword: keyword }),
            PriceMax: priceMax,
            PriceMin: priceMin,
            sortBy: sort,
            limit: limit,
            offset: currentOffset
        }).then(({ data }) => {
            setTotalProducts(data.Data.ProductTotal)
            if(currentOffset === 0){
                setProducts(data.Data.Products)
            }
            else{
                setProducts((prev)=>[...prev,...data.Data.Products])
            }
            setBtnLoading(false);
            setLoading(false)
            loader.current = false;
        }).catch((e) => {
            console.log(e)
        })
    }

    const fetchFilters = (gender = null, category = null, tag = null, brand = null, keyword = null) => {
        getFilters().then(({ data }) => {
            if (data.Status) {
                // Destructure data.Data for readability
                const { PriceMinAllowed: priceMinAllowed, PriceMaxAllowed: priceMaxAllowed, AllAge: ageOptionsData, Ratings: ratingOptionsData, AllBrands: brandsOptionsData, AllGender: parentCategoriesData, AllCategories: childCategoriesData } = data.Data;

                // Map over age and rating options, setting active to false for all entries
                const ageOptions = ageOptionsData.map(item => ({ ...item, active: false }));
                const ratingOptions = ratingOptionsData.map(item => ({ ...item, active: false }));

                // Initialize child and grandchild categories
                const childCategories = childCategoriesData;
                const grandChildCategories = childCategoriesData.flatMap(item => item.Sub_SubCat).map((gc)=>({ ...gc, active: category && category.length && category[0] && gc.Id === category[0] ? true : false, disabled: false }));

                // Map parent categories with child and grandchild category IDs
                const parentCategories = parentCategoriesData.map(parent => {
                    const allChildCategories = childCategories.filter(child => child.ParentId === parent.Id);
                    const allGrandChildCategories = allChildCategories.flatMap(child => child.Sub_SubCat);
                    return { ...parent, allChilds: allChildCategories.map(child => child.Id), allGrandChilds: allGrandChildCategories.map(gc => gc.Id), active: gender && gender.length && gender[0] === parent.Id ? true : false };
                });

                // Map child categories with grandchild category IDs and disable/active state
                const updatedChildCategories = childCategories.map(child => {
                    const allGrandChildCategories = grandChildCategories.filter(gc => gc.ParentId === child.Id);
                    const disabled = gender && gender.length && gender[0] !== child.ParentId;
                    const active = !disabled && category && category.length && category[0] === child.Id;
                    return { ...child, allChilds: allGrandChildCategories.map(gc => gc.Id), active, disabled };
                });

                let updatedGrandChildCategories = grandChildCategories;
                if(gender && gender.length && gender[0]){
                    let activeParent = parentCategories.find((pc)=>pc.active);
                    updatedGrandChildCategories = grandChildCategories.map((gc)=>({ ...gc, disabled: activeParent ? activeParent.allGrandChilds.includes(gc.Id) ? false : true : false  }))
                }
                if(category && category.length && category[0]){
                    let activeParent = updatedChildCategories.find((pc)=>pc.active);
                    updatedGrandChildCategories = grandChildCategories.map((gc)=>({ ...gc, disabled: activeParent ? activeParent.allChilds.includes(gc.Id) ? false : true : false  }))
                }
                

                // Map brand options with active state
                const brandsOptions = brandsOptionsData.map(b => ({ ...b, active: brand && brand.length && brand[0] === b.Id ? true : false }));

                // Set filters with the processed data
                setFilters({ age: ageOptions, brands: brandsOptions, rating: ratingOptions.reverse(), parentCategories, childCategories: updatedChildCategories, grandChildCategories: updatedGrandChildCategories, special: tag, keyword, priceMinAllowed, priceMaxAllowed, priceMin: priceMinAllowed, priceMax: priceMaxAllowed, activePriceMin: priceMinAllowed, activePriceMax: priceMaxAllowed });
                setSidebarLoading(false);
            }
        }).catch((e) => {
            console.log(e)
        })
    }

    const getAdditionalParams = () => {
        let gender = null;
        let category = null;
        let brand = null;
        let tag = null;
        let keyword = null;
        if (search && search.get('gender') && !isNaN(search.get('gender'))) {
            gender = parseInt(search.get('gender'));
        }
        if (search && search.get('category') && !isNaN(search.get('category'))) {
            category = parseInt(search.get('category'));
        }
        if (search && search.get('brand') && !isNaN(search.get('brand'))) {
            brand = parseInt(search.get('brand'));
        }
        if (search && search.get('tag') && !isNaN(search.get('tag'))) {
            tag = parseInt(search.get('tag'));
        }
        if (search && search.get('keyword') && search.get('keyword').length > 2) {
            keyword = search.get('keyword');
        }
        return { category: category ? [category] : null, brand: brand ? [brand] : null, tag: tag, gender: gender ? [gender] : null, keyword: keyword };
    }

    const getActiveFilters = () => {
        let gender = filters.parentCategories.filter((pc)=>pc.active).map((pc)=>pc.Id);
        let category = filters.childCategories.filter(cc => cc.active).map(cc => cc.Id);
        let subcategory = filters.grandChildCategories.filter(gc => gc.active).map(gc => gc.Id);
        let brand = filters.brands.filter((b)=>b.active).map((b)=>b.Id);
        let age = filters.age.filter((a)=>a.active).map((a)=>a.Id);
        let rating = filters.rating.find((r)=>r.active) ? filters.rating.find((r)=>r.active).Value : null
        let tag = filters.special ? filters.special : null;
        let keyword = filters.keyword ? filters.keyword : null;
        let activePriceMax = filters.activePriceMax;
        let activePriceMin = filters.activePriceMin;
        return { category: category && category.length ? category : null, subcategory: subcategory && subcategory.length ? subcategory : null, brand: brand && brand.length ? brand : null, special: tag, gender: gender && gender.length ? gender : null, keyword: keyword, rating: rating ? rating : null, age: age && age.length ? age : null, PriceMax: activePriceMax, PriceMin: activePriceMin };
    }

    const initializeListener = () => {
        window.addEventListener('scroll', function () {
            var targetElement = document.getElementById('loadMoreProducts'); // Replace with your element's ID
            if (targetElement) {
                var elementPosition = targetElement.getBoundingClientRect();
                if (elementPosition.top >= 0 && elementPosition.bottom <= window.innerHeight) {
                    if (!loader.current) {
                        loader.current = true;
                        loadMore();
                    }
                }
            }
        });
    }

    const removeGroupFilter = (type) => {
        let parentCategories = [];
        let childCategories = [];
        let grandChildCategories = [];
        switch (type) {
            case "parentCategory":
                parentCategories = filters.parentCategories.map((pc)=>({ ...pc, active: false }));
                childCategories = filters.childCategories.map((pc)=>({ ...pc, disabled: false }));;
                grandChildCategories = filters.grandChildCategories;
                let activeChildren = childCategories.filter((pc)=>pc.active);
                let activeChildrenIds = activeChildren.map((pc)=>pc.Id);
                if(activeChildren && activeChildren.length){
                    grandChildCategories = grandChildCategories.map((gc)=>( activeChildrenIds.includes(gc.ParentId) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
                }
                else{
                    grandChildCategories = grandChildCategories.map((gc)=>({...gc, disabled: false}))
                }
                setFilters((prev)=>({...prev, parentCategories: parentCategories, childCategories: childCategories, grandChildCategories: grandChildCategories}))
                break;
            case "childCategory":
                parentCategories = filters.parentCategories;
                childCategories = filters.childCategories.map((pc)=>({ ...pc, active: false }));;
                grandChildCategories = filters.grandChildCategories;
                let activeParents = parentCategories.filter((pc)=>pc.active);
                if(activeParents && activeParents.length){
                    grandChildCategories = grandChildCategories.map((gc)=>( activeParents.find((ap)=>ap.allGrandChilds.includes(gc.Id)) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
                }
                else{
                    grandChildCategories = grandChildCategories.map((gc)=>({...gc, disabled: false}))
                }
                setFilters((prev)=>({...prev, childCategories: childCategories, grandChildCategories: grandChildCategories}))
                break;
            case "brands":
                setFilters((prev)=>({...prev, brands: prev.brands.map((b)=>({...b, active: false}))}))
                break;
            case "age":
                setFilters((prev)=>({...prev, age: prev.age.map((a)=>({...a, active: false}))}))
                break;
            case "rating":
                setFilters((prev)=>({...prev, rating: prev.rating.map((r)=>({...r, active: false}))}))
                break;
            default:
                parentCategories = filters.parentCategories.map((pc)=>({ ...pc, active: false }));
                childCategories = filters.childCategories.map((cc)=>({ ...cc, active: false, disabled: false }));
                grandChildCategories = filters.grandChildCategories.map((gc)=>({ ...gc, disabled: false }));
                let brands = filters.brands.map((r)=>({...r, active: false}))
                let age = filters.age.map((r)=>({...r, active: false}))
                let rating = filters.rating.map((r)=>({...r, active: false}))
                setFilters((prev)=>({...prev, parentCategories: parentCategories, childCategories: childCategories, grandChildCategories: grandChildCategories, brands: brands, age: age, rating:rating, keyword: null }))
                break;
        }
        setRefresh(refresh+1)
    }

    const isFilterActive = (type = null) => {
        switch (type) {
            case "keyword":
                return filters.keyword && filters.keyword.length ? true : false;
            case "parentCategory":
                return filters.parentCategories.find((i)=>i.active) ? true : false;
            case "childCategory":
                return filters.childCategories.find((i)=>i.active && !i.disabled) ? true : false;
            case "brands":
                return filters.brands.find((i)=>i.active) ? true : false;
            case "age":
                return filters.age.find((i)=>i.active) ? true : false;
            case "rating":
                return filters.rating.find((i)=>i.active) ? true : false;
            default:
                break;
        }
        return filters.childCategories.find((i)=>i.active) || (filters.keyword && filters.keyword.length) || filters.parentCategories.find((i)=>i.active) || filters.age.find((i)=>i.active) || filters.brands.find((i)=>i.active)
    }


    const addRemoveFilter = (active,filter,type) => {
        if(type==="parentCategory"){
            let parentCategories = filters.parentCategories.map((pc)=>{ return pc.Id === filter.Id ? { ...pc, active: active } : pc });
            let childCategories = filters.childCategories;
            let grandChildCategories = filters.grandChildCategories;
            let activeParents = parentCategories.filter((pc)=>pc.active);
            let activeParentIds = activeParents.map((pc)=>pc.Id);
            if(activeParents && activeParents.length){
                childCategories = childCategories.map((cc)=>( activeParentIds.includes(cc.ParentId) ? {...cc, disabled: false} : {...cc, disabled: true, active: false} ))
                let activeChildren = childCategories.filter((pc)=>pc.active);
                let activeChildrenIds = activeChildren.map((pc)=>pc.Id);
                if(activeChildren && activeChildren.length){
                    grandChildCategories = grandChildCategories.map((gc)=>( activeChildrenIds.includes(gc.ParentId) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
                }
                else{
                    grandChildCategories = grandChildCategories.map((gc)=>( activeParents.find((ap)=>ap.allGrandChilds.includes(gc.Id)) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
                }
            }
            else{
                let activeChildren = childCategories.filter((pc)=>pc.active);
                let activeChildrenIds = activeChildren.map((pc)=>pc.Id);
                if(activeChildren && activeChildren.length){
                    grandChildCategories = grandChildCategories.map((gc)=>( activeChildrenIds.includes(gc.ParentId) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
                }
                else{
                    childCategories = childCategories.map((cc)=>({...cc, disabled: false}))
                    grandChildCategories = grandChildCategories.map((gc)=>({...gc, disabled: false}))
                }
            }
            setFilters((prev)=>({...prev, parentCategories: parentCategories, childCategories: childCategories, grandChildCategories: grandChildCategories}))
        }
        if(type==="childCategory"){
            let childCategories = filters.childCategories.map((cc)=>{ return cc.Id === filter.Id ? { ...cc, active: active } : cc });;
            let grandChildCategories = filters.grandChildCategories;
            let parentCategories = filters.parentCategories;
            let activeParents = parentCategories.filter((pc)=>pc.active);
            let activeChildren = childCategories.filter((cc)=>cc.active);
            let activeChildrenIds = activeChildren.map((ac)=>ac.Id);
            if(activeChildren && activeChildren.length){
                grandChildCategories = grandChildCategories.map((gc)=>( activeChildrenIds.includes(gc.ParentId) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
            }
            else{
                if(activeParents && activeParents.length){
                    grandChildCategories = grandChildCategories.map((gc)=>( activeParents.find((ap)=>ap.allGrandChilds.includes(gc.Id)) ? {...gc, disabled: false} : {...gc, disabled: true, active: false} ))
                }
                else{
                    grandChildCategories = grandChildCategories.map((gc)=>({...gc, disabled: false}))
                }
            }
            setFilters((prev)=>({...prev, childCategories: childCategories, grandChildCategories: grandChildCategories}))
        }
        if(type==="grandChildCategory"){
            let grandChildCategories = filters.grandChildCategories.map((gc)=>{ return gc.Id === filter.Id ? { ...gc, active: active } : gc });;
            setFilters((prev)=>({...prev, grandChildCategories: grandChildCategories}))
        }
        if(type==="brand"){
            let brands = filters.brands.map((b)=>{ return b.Id === filter.Id ? { ...b, active: active } : b });;
            setFilters((prev)=>({...prev, brands: brands}))
        }
        if(type==="keyword"){
            setFilters((prev)=>({...prev, keyword: ""}))
        }
        if(type==="age"){
            let age = filters.age.map((a)=>{ return a.Id === filter.Id ? { ...a, active: active } : a });;
            setFilters((prev)=>({...prev, age: age}))
        }
        if(type==="rating"){
            let rating = filters.rating.map((a)=>{ return a.Value === filter.Value ? { ...a, active: active } : { ...a, active: false } });;
            setFilters((prev)=>({...prev, rating: rating}))
        }
        setRefresh(refresh+1)
    }

    const handleRange = (e) => {
        setFilters((prev)=>({...prev, priceMin: parseInt(e.minValue), priceMax: parseInt(e.maxValue)}))
    };

    const updatePrice = () => {
        setFilters((prev)=>({...prev, activePriceMin: parseInt(filters.priceMin), activePriceMax: parseInt(filters.priceMax)}))
        setRefresh(refresh+1)
    }

    const loadMore = () => {
        setOffset((prev) => prev + 12);
    }

    return (
        <>
            <BreadCrumbs pageTile="Store" breadcrumbItems={breadcrumbItems} />
            <section id="productList">
                {
                    loading ?
                        <div className="wrapper d-flex justify-between">
                            <aside className="product-sidebar">
                                <Skeleton height="1315px" style={{ width: "100%" }} />
                            </aside>
                            <div className="products-container">
                                <div className="banner-image-container">
                                    <Skeleton height="230px" style={{ width: "100%", borderRadius: "5px" }} />
                                </div>
                                <div className="d-flex justify-between mb-5">
                                    <Skeleton height="42px" style={{ width: "150px" }} />
                                    <Skeleton height="42px" style={{ width: "150px" }} />
                                </div>
                                <div className="products-wrapper d-flex flex-wrap justify-between">
                                    {
                                        [...Array(8)].map((x, i) => (
                                            <div className="product-card p-0 border-none dummy" key={i}>
                                                <Skeleton height="460px" style={{ width: "100%" }} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div> :
                        <div className={showSidebar ? "wrapper d-flex justify-between sidebar-open" : "wrapper d-flex justify-between"}>
                            {
                                sidebarLoading ?
                                <aside className="product-sidebar">
                                    <Skeleton height="800px" style={{width: "100%"}} />
                                </aside> : 
                                <aside className="product-sidebar">
                                    <div className="product-sidebar-overlay" onClick={()=>{setShowSidebar(!showSidebar)}}></div>
                                    <button className="product-sidebar-close" onClick={()=>{setShowSidebar(!showSidebar)}}><i className="ri-close-line"></i></button>
                                    <div className="product-sidebar-wrapper">
                                        <div className="sidebar-current-filters mb-4">
                                            <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Filters</T></h4>
                                                { isFilterActive() ? <button onClick={()=>{removeGroupFilter()}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                                            </div>
                                            <div className="current-filters">
                                                { isFilterActive() ? null : <p className="mb-0 mt-0 text-gray-400"><T>No filters selected</T></p> }
                                                { isFilterActive("keyword") ? <span className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ addRemoveFilter(false,"","keyword") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{filters.keyword}</span> : null }
                                                { filters.parentCategories.filter(pc=>pc.active).map((pc,key) => { return <span key={key+"parentCategories"} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ addRemoveFilter(false,pc,"parentCategory") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{pc.Value}</span> }) }
                                                { filters.childCategories.filter(cc=>cc.active).map((cc,key) => { return <span key={key+"childCategories"} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ addRemoveFilter(false,cc,"childCategory") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{cc.Value}</span> }) }
                                                { filters.brands.filter(b=>b.active).map((b,key) => { return <span key={key+"brands"} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ addRemoveFilter(false,b,"brand") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{b.Value}</span> }) }
                                                { filters.age.filter(a=>a.active).map((a,key) => { return <span key={key+"age"} className="fs-14 text-gray-500 fw-500 d-inline-flex align-items-center bg-alternative pl-2 pr-3 pt-1 pb-1 mr-2 mb-2"><i onClick={(e)=>{ addRemoveFilter(false,a,"age") }} className="ri-close-line fs-12 mr-2 cursor-pointer"></i>{a.Value}</span> }) }
                                            </div>
                                        </div>
                                        {
                                            filters.parentCategories.length ?
                                            <div className="sidebar-gender mb-4">
                                                <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                    <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Gender</T></h4>
                                                    { isFilterActive("parentCategory") ? <button onClick={()=>{removeGroupFilter("parentCategory")}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                                                </div>
                                                <div className="gender-filters">
                                                    {
                                                        filters.parentCategories.map((pc,index)=>{
                                                            return <div className="mb-3" key={"parentCategory"+index}>
                                                                <input onChange={(e)=>{addRemoveFilter(e.target.checked,pc,"parentCategory")}} type={"checkbox"} id={"parentCategory"+index} className="input-checkbox" value={pc.Value} checked={ pc.active ? true : false } />
                                                                <label htmlFor={"parentCategory"+index} className="fs-14 fw-500 text-gray-500">{pc.Value}</label>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                        }
                                        {
                                            filters.childCategories.filter((i)=>!i.disabled).length ?
                                            <div className="sidebar-category mb-4">
                                                <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                    <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Categories</T></h4>
                                                    { isFilterActive("childCategory") ? <button onClick={()=>{removeGroupFilter("childCategory")}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                                                </div>
                                                <div className={"category-filters active"}>
                                                    {
                                                        expandChildCategories ?
                                                        filters.childCategories.filter((i)=>!i.disabled).map((cc,index)=>(
                                                            <div className="mb-3" key={"childCategory"+index}>
                                                                <input onChange={(e)=>{addRemoveFilter(e.target.checked,cc,"childCategory")}} type={"checkbox"}  id={"childCategory"+index} className="input-checkbox" value={cc.Value} checked={cc.active ? true : false }/>
                                                                <label htmlFor={"childCategory"+index} className="fs-14 fw-500 text-gray-500">{cc.Value}</label>
                                                            </div>
                                                        )) :
                                                        filters.childCategories.filter((i)=>!i.disabled).slice(0,10).map((cc,index)=>(
                                                            <div className="mb-3" key={"childCategory"+index}>
                                                                <input onChange={(e)=>{addRemoveFilter(e.target.checked,cc,"childCategory")}} type={"checkbox"}  id={"childCategory"+index} className="input-checkbox" value={cc.Value} checked={cc.active ? true : false }/>
                                                                <label htmlFor={"childCategory"+index} className="fs-14 fw-500 text-gray-500">{cc.Value}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    filters.childCategories.filter((i)=>!i.disabled).length > 10 ?
                                                    <span className="text-primary d-inline-block mt-2 cursor-pointer fs-14"  onClick={()=>{setExpandChildCategories(!expandChildCategories)}}>{ expandChildCategories ? <T>Show Less</T> : <T>Show More</T>}</span> : null
                                                }
                                            </div> : null
                                        }
                                        {
                                            filters.brands.length ?
                                            <div className="sidebar-brand mb-4">
                                                <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                    <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Brands</T></h4>
                                                    { isFilterActive("brands") ? <button onClick={()=>{removeGroupFilter("brands")}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                                                </div>
                                                <div className={"brand-filters active"}>
                                                    {
                                                        expandBrands ?
                                                        filters.brands.map((b,index)=>(
                                                            <div className="mb-3" key={"brand"+index}>
                                                                <input onChange={(e)=>{addRemoveFilter(e.target.checked,b,"brand")}} type={"checkbox"}  id={"brand"+index} className="input-checkbox" checked = { b.active } />
                                                                <label htmlFor={"brand"+index} className="fs-14 fw-500 text-gray-500">{b.Value}</label>
                                                            </div>
                                                        )) :
                                                        filters.brands.slice(0,10).map((b,index)=>(
                                                            <div className="mb-3" key={"brand"+index}>
                                                                <input onChange={(e)=>{addRemoveFilter(e.target.checked,b,"brand")}} type={"checkbox"}  id={"brand"+index} className="input-checkbox" checked = { b.active } />
                                                                <label htmlFor={"brand"+index} className="fs-14 fw-500 text-gray-500">{b.Value}</label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                {
                                                    filters.brands.length > 10 ?
                                                    <span className="text-primary d-inline-block mt-2 cursor-pointer fs-14" onClick={()=>{setExpandBrands(!expandBrands)}}>{ expandBrands ? <T>Show Less</T> : <T>Show More</T>}</span> : null
                                                }
                                            </div> : null
                                        }
                                        <div className="sidebar-price mb-5">
                                            <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Price</T></h4>
                                            </div>
                                            <div className="filter-range d-flex align-items-center justify-between mb-4 LTR text-left">
                                                <div className="">
                                                    <label htmlFor="rangeMin" className="fs-14 fw-400 text-gray-500"><T>Min</T></label>
                                                    <input className="w-100" id="rangeMin" placeholder="Min Amount" type={"text"} min={filters.priceMinAllowed} max={filters.priceMaxAllowed-1} value={"SAR " +filters.priceMin} readOnly={true}/>
                                                </div>
                                                <div className="">
                                                    <label htmlFor="rangeMax" className="fs-14 fw-400 text-gray-500"><T>Max</T></label>
                                                    <input className="w-100" id="rangeMax" placeholder="Max Amount" type={"text"} min={filters.priceMinAllowed+1} max={filters.priceMaxAllowed} value={"SAR " +filters.priceMax} readOnly={true}/>
                                                </div>
                                            </div>
                                            <div className="price-filter mb-5 LTR text-left">
                                                <MultiRangeSlider min={filters.priceMinAllowed} max={filters.priceMaxAllowed} step={1} ruler={true} label={true} preventWheel={false} minValue={filters.priceMin} maxValue={filters.priceMax} labels={["SAR","SAR"]} onInput={handleRange}/>
                                            </div>
                                            <button className="btn btn-outline-secondary w-100 justify-center" type="button" onClick={updatePrice}><T>Apply</T></button>
                                        </div>
                                        {
                                            filters.age.length ?
                                            <div className="sidebar-age mb-4">
                                                <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                    <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>Age</T></h4>
                                                    { isFilterActive("age") ? <button onClick={()=>{removeGroupFilter("age");}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                                                </div>
                                                <div className="age-filters">
                                                    {
                                                        filters.age.map((age,index)=>(
                                                            <div className="mb-3" key={"age"+index}>
                                                                <input onChange={(e)=>{addRemoveFilter(e.target.checked,age,"age")}} type={"checkbox"}  id={"age"+index} className="input-checkbox" value={age.Value} checked = { age.active } />
                                                                <label htmlFor={"age"+index} className="fs-14 fw-500 text-gray-500"><span className="LTR">{age.Value}</span></label>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div> : null
                                        }
                                        
                                        <div className="sidebar-rating mb-4">
                                            <div className="sidebar-section-header d-flex align-items-center justify-between">
                                                <h4 className="sidebar-heading fs-16 fw-500 text-gray-500"><T>By Rating</T></h4>
                                                { isFilterActive("rating") ? <button onClick={()=>{removeGroupFilter("rating")}} className="link fs-14" type="button"><T>Clear All</T></button> : null }
                                            </div>
                                            <div className="rating-filters">
                                                {
                                                    filters.rating.map((rate,index)=>{
                                                        return <button key={"rating"+index} className={rate.active ? "active" : ""} onClick={()=>{addRemoveFilter(true,rate,"rating")}}>
                                                            { [...Array(5)].map((x,i)=> i < rate.Value ? <i key={"star"+i} className="ri-star-fill"></i> : <i key={"star"+i} className="ri-star-line"></i> )}{" "}{ rate.Value < 5 ? <T>& Up</T> : null }
                                                        </button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                            }
                            <div className="products-container">
                                <div className="banner-image-container">
                                    <LazyLoadImage src={coverImage} className="banner-image" alt=""/>
                                    <div className="banner-image-text">
                                        <h4><T>ClickToys</T></h4>
                                        <h6><T>A world filled with</T> <span><T>Happiness</T></span> <T>and</T> <span><T>Fun</T></span></h6>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-between">
                                    <div className="products-sort">
                                        <label htmlFor="productSort"><T>Sort By:</T></label>
                                        <select className="productSort" id="productSort" name="productSort" defaultValue={sortBy} onChange={(e)=>{ setSortBy(parseInt(e.target.value)); setRefresh(refresh+1) }}>
                                            <option value="0"><T>Most Recent</T></option>
                                            <option value="1"><T>Most Popular</T></option>
                                            <option value="2"><T>Lowest Price</T></option>
                                            <option value="3"><T>Highest Price</T></option>
                                        </select>
                                    </div>
                                    <div className="products-filter-handle">
                                        <button className="btn-filter" type="buton" onClick={()=>{setShowSidebar(!showSidebar)}} ><i className="ri-equalizer-line mr-3"></i><T>Filters</T></button>
                                    </div>
                                </div>
                                {
                                    sidebarLoading ?
                                    <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                        <div className="d-flex grand-child-categories">
                                            {
                                                [...Array(25)].map((gc,i)=>{
                                                    return <span className={`grand-child-category loading`} key={i+"grandChildCategory"}><Skeleton height="31px" width={"116px"} style={{minWidth: "120px"}} /></span>
                                                }) 
                                            }
                                        </div>
                                    </ScrollContainer> : 
                                    filters.grandChildCategories && filters.grandChildCategories.length ?
                                    <ScrollContainer className="scroll-container" horizontal={true} vertical={false}>
                                        <div className="d-flex grand-child-categories">
                                            {
                                                filters.grandChildCategories.filter(gc=>!gc.disabled).map((gc,i)=>{
                                                    return <span onClick={()=>{addRemoveFilter(!gc.active,gc,"grandChildCategory")}} className={`grand-child-category ${gc.active ? "active" : ""}`} key={i+"grandChildCategory"}>{gc.Value}</span>
                                                }) 
                                            }
                                        </div>
                                    </ScrollContainer> : null
                                }
                                {
                                    firstHit ?
                                    searchResults.length ?
                                    <div className={"products-wrapper"}>
                                        {
                                            searchResults.map((item,index)=>{
                                                return <ProductCardAlgolia key={index} item={item} pageTitle="Product Listing" permissions={props.consent}/>
                                            })
                                        }
                                    </div> : 
                                    <p className="btn btn-alternative d-flex align-items-center m-0 w-100"><i className="ri-information-line fs-22 mr-2"></i><T>No Products Found. Please try a different filter.</T></p> :
                                    products.length ? 
                                    <div className={"products-wrapper"}>
                                        {
                                            products.map((item,index)=>{
                                                return <ProductCard key={index} item={item} pageTitle="Product Listing" permissions={props.consent}/>
                                            })
                                        }
                                    </div> : 
                                    <p className="btn btn-alternative d-flex align-items-center m-0 w-100"><i className="ri-information-line fs-22 mr-2"></i><T>No Products Found. Please try a different filter.</T></p>
                                }
                                {
                                    !firstHit && totalProducts > products.length ?
                                    <div className="products-pagination d-flex align-items-center justify-center mt-3">
                                        {
                                            btnLoading ?
                                            <button className="btn btn-outline-primary" type="button"><T>Loading...</T></button> :
                                            <button className="btn btn-outline-primary" id="loadMoreProducts" type="button" onClick={loadMore}><T>Show More Products</T></button>
                                        }
                                    </div> : <></>
                                }
                            </div>
                        </div>
                }
            </section>
        </>
    )
}
export default Products;
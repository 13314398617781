import React, { useState, useEffect, useRef, useContext } from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import '../../assets/css/--component-checkout.css'
import CheckoutProduct from "./checkout-product";
import { Link, useNavigate } from "react-router-dom"
import { applyCoupon, cartCheckout, getCheckoutDetails, getProductCart, removeCouponCode } from "../../helper/backend-methods";
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { cardIcons } from "./payment-cards";
import failedIcon from '../../assets/images/failed-icon.png';
import { analyticsPageEvent, analyticsTrackEvent, tiktokConversion, twitterConversion } from "../../components/Analytics";
import { GlobalContext } from "../../context";
import T, { _T } from "../../locale/translations-main";
import {app} from '../../helper/backend-methods'
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import { LazyLoadImage } from "react-lazy-load-image-component";

function CheckoutPage(props) {
    const [config, setConfig] = useState({ Maintenance: false, EnableFacebookLogin: false, EnableGoogleLogin: false, EnableTamara: false})
    const { updateCartCount } = useContext(GlobalContext);
    const [showTab, setTabShow] = useState(0);
    const [loading, setLoading] = useState(true)
    const [checkoutLoading, setCheckoutLoading] = useState(false)
    const [apiFailed, setApiFailed] = useState(null)
    const [apiResponse, setApiResponse] = useState(null)
    const [apiResponseTemp, setApiResponseTemp] = useState(null)
    const [apiResponseStatus, setApiResponseStatus] = useState(false)
    const [sameBilling, setSameBilling] = useState(true)
    const [shippingAddress, setShippingAddress] = useState(0)
    const [billingAddress, setBillingAddress] = useState(0)
    const [paymentMethod, setPaymentMethod] = useState("")
    const [emailRequired, setEmailRequired] = useState(false)
    const [emailVisible, setEmailVisible] = useState(false)
    const [isArabic, setIsArabic] = useState(false)
    const [language, setLanguage] = useState("en")


    const [previousCards, setPreviousCards] = useState([])
    const userRef = useRef({ "CustomerId": 0, "CustomerGuid": null, "FullName": "", "ProfilePicture": "", "Email": "", "Phone": "" })
    const [userInfo, setUserInfo] = useState({})
    const [paymentMethods, setPaymentMethods] = useState([])
    const [cartItems, setCartItems] = useState([])
    const [addresses, setAddresses] = useState([])
    const [cartSummary, setCartSummary] = useState(null)

    const [addressUnknown, setAddressUnknown] = useState(false)
    const [recipientFirstName, setRecipientFirstName] = useState("")
    const [recipientLastName, setRecipientLastName] = useState("")
    const [recipientPhoneNo, setRecipientPhoneNo] = useState("")
    const [recipientFirstNameError, setRecipientFirstNameError] = useState(true)
    const [recipientLastNameError, setRecipientLastNameError] = useState(true)
    const [recipientPhoneNoError, setRecipientPhoneNoError] = useState(true)


    const [outOfStock, setOutOfStock] = useState(false);
    const [isNotAvailable, setIsNotAvailable] = useState(false);

    const [showCardNumberError, setShowCardNumberError] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [showExpiryError, setShowExpiryError] = useState(false);
    const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
    const [showCVCError, setShowCVCError] = useState(false);
    const [isCardCVCValid, setIsCardCVCValid] = useState(false);
    const [useNewCard, setUseNewCard] = useState(false);
    const [previousCard, setPreviousCard] = useState(null);
    const [previousCardCVV, setPreviousCardCVV] = useState("");
    const [isPreviousCardCVVValid, setIsPreviousCardCVVValid] = useState(false);
    const [showPreviousCardCVVValid, setShowPreviousCardCVVValid] = useState(false);
    const [storeCard, setStoreCard] = useState(false);
    const [selectedCardIcon, setSelectedCardIcon] = useState(cardIcons["undefined"]);
    const [conditionChecked, setConditionChecked] = useState(true);
    const [checkout,setCheckout] = useState(false)
    const [tokenObject,setTokenObject] = useState(null)
    const [popupCVV,setPopupCVV] = useState(false)
    const [popupConfirmation,setPopupConfirmation] = useState(false)
    const [checkoutURL,setCheckoutURL] = useState("")
    const [cartError, setCartError] = useState(false)
    const eventExecuted = useRef(false);
    const eventOrderData = useRef(null);
    const eventCartItems = useRef([]);
    const [loadingCoupon,setLoadingCoupon] = useState("")
    const [couponCode,setCouponCode] = useState("")
    const [couponError,setCouponError] = useState("")
    const [updating,setUpdating] = useState(false)
    const [cartUpdate,setCartUpdate] = useState(0)
    const [voucher,setVoucher] = useState(null)
    const [availablePayments,setAvailablePayments] = useState([])

    const [enableEmail, setEnableEmail] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [showTamara, setShowTamara] = useState(false);
    const [isTamara, setIsTamara] = useState(false);
    const [minimize, setMinimize] = useState(false);
    const tamaraActionPending = useRef(true)
    
    const eventCartData = useRef({"Discount": 0, "SubTotal": 0, "TotalAmount": 0});

    const breadcrumbItems = [
        { title: "Home", link: "/" },
        { title: "Checkout", link: "" },
    ]

    const couponErrorCodes = {
        1068: { "en" : "Coupon Code Not Found" , "ar" : "رمز القسيمة غير موجود."},
        1065: { "en" : "Coupon Code Already Applied" , "ar" : "رمز القسيمة مطبق بالفعل." },
        1044: { "en" : "Coupon not valid for current product(s)" , "ar" : "القسيمة غير صالحة للمنتج (المنتجات) الحالية."},
        1018: { "en" : "Oops, this discount is all used up! It has reached its maximum usage limit. Please check out our other deals and promotions!" , "ar" : "عفوًا ، تم استخدام هذا الخصم بالكامل! لقد وصلت إلى الحد الأقصى للاستخدام. يرجى التحقق من صفقاتنا وعروضنا الترويجية الأخرى!"},
    }


    const addressTitles = {
        "home": {
            "en": "Home",
            "ar": "بيت",
        },
        "partner": {
            "en": "Partner",
            "ar": "شريك",
        },
        "work": {
            "en": "Work",
            "ar": "عمل",
        }
    }

    function getQueryParamManual(param) {
        const queryString = window.location.search.substring(1);
        const params = queryString.split("&");
        for (let i = 0; i < params.length; i++) {
            const pair = params[i].split("=");
            if (pair[0] === param) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    useEffect(()=>{
        getConfig().then((response)=>{
            setConfig(JSON.parse(response._value))
        }).catch((e)=>{
        })
    },[])


    const getConfig = async () =>{
        const firebaseApp = app;
        const remoteConfig = getRemoteConfig(firebaseApp);
        remoteConfig.settings.minimumFetchIntervalMillis = 5000;
        await fetchAndActivate(remoteConfig)
        return getValue(remoteConfig, process.env.REACT_APP_FIREBASE_REMOTE_CONFIG);
    }

    const failureReasonTranslate = (status) => {
        const failureReasons = {
            1066: {
                en: "Unable to place your order because your phone number is missing on your profile. Please add it first to continue.",
                ar: "غير قادر على تقديم طلبك لأن رقم هاتفك غير متوفر في ملفك الشخصي. يرجى إضافته أولاً للمتابعة."
            },
            1063: {
                en: "Certain items in your cart have exceed maximum stock quantity available. We've adjusted the stock quantity in your cart to proceed. Please try again.",
                ar: "تجاوزت بعض العناصر في سلة التسوق الحد الأقصى المتاح لكمية المخزون. لقد قمنا بضبط كمية المخزون في سلة التسوق للمتابعة. يرجى المحاولة مرة أخرى."
            },
            1064: {
                en: "Certain items in your cart have just run out of stock. Please re-check your cart and try again.",
                ar: "تم نفاد بعض العناصر في سلة التسوق الخاصة بك. يرجى إعادة التحقق من سلة التسوق الخاصة بك والمحاولة مرة أخرى."
            },
            1041: {
                en: "An item in your cart has already been purchased for the occasion. Please try again!",
                ar: "تم شراء أحد العناصر في سلة التسوق بالفعل لهذه المناسبة. يرجى المحاولة مرة أخرى!"
            },
            1053: {
                en: "Your address could not be found. Please try again with a different address",
                ar: "تعذر العثور على عنوانك. يرجى المحاولة مرة أخرى باستخدام عنوان مختلف."
            },
            1033: {
                en: "We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!",
                ar: "نأسف للإزعاج ولكن تم إلغاء طلبك بسبب تعذُّر معالجة الدفع. حاول مرة اخرى!",
            },
            1029: {
                en: "We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!",
                ar: "نأسف للإزعاج ولكن تم إلغاء طلبك بسبب تعذُّر معالجة الدفع. حاول مرة اخرى!",
            },
            1018: {
                en: "We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!",
                ar: "نأسف للإزعاج ولكن تم إلغاء طلبك بسبب تعذُّر معالجة الدفع. حاول مرة اخرى!",
            },
            1007: {
                en: "The email address you provided is already associated with another account. Please provide a different email address to continue.",
                ar: "البريد الإلكتروني الذي قدمته مرتبط بالفعل بحساب آخر. يرجى تقديم عنوان بريد إلكتروني مختلف للمتابعة.",
            },
        }
        return failureReasons[status] ? failureReasons[status] : { en: "We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!", ar: "نأسف للإزعاج ولكن تم إلغاء طلبك بسبب تعذُّر معالجة الدفع. حاول مرة اخرى!", }
    }
    const history = useNavigate();

    const scrollToTop = (t = 0) =>{
        window.scrollTo({ top: t+'px', left: 0, behavior: "smooth" });
    }
    
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        const lang = localStorage.getItem("LANG")
        if(lang && lang.length){
            setLanguage(lang)
        }
    },[])

    useEffect(() => {
        const accessToken = localStorage.getItem("authToken")
        const isAuthenticated = accessToken && accessToken.length ? true : false;
        let lang = localStorage.getItem("LANG")
        if (lang && lang.length) {
            if (lang === "ar") {
                setIsArabic(true)
                setLanguage("ar")
            }
            else{
                lang = "en"
            }
        }
        else{
            lang = "en"
        }

        const buyNowQuantity = getQueryParamManual('q');
        const buyNowProduct = getQueryParamManual('p'); 
        let query = {};
        if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
            query = { "productId":buyNowProduct, "quantity":buyNowQuantity }
        }
        if (isAuthenticated) {
            if(props.consent.page){
                analyticsPageEvent("Checkout");
            }
            getCheckoutDetails(query).then(({ data }) => {
                if (data.Status) {
                    setPreviousCards(data.Data.CardList)
                    setUseNewCard(data.Data.CardList.length ? false : true)
                    setUserInfo(data.Data.UserInfo)
                    setEnableEmail(!(data.Data.UserInfo && data.Data.UserInfo.Email && data.Data.UserInfo.Email.length))
                    userRef.current = data.Data.UserInfo
                    setPaymentMethods(data.Data.PaymentMethod)
                    setAddresses(data.Data.Addresses)
                    setPaymentMethod(data.Data.PaymentMethod[0].SystemName)
                    setPreviousCard(data.Data.CardList.find((c) => c.Selected))
                    
                    analyticsTrackEvent("Payment Card", { 
                        cards: data.Data.CardList, 
                        items_count: data.Data.CardList.length
                    })
                    data.Data.Addresses.map((address) => {
                        if (address.IsDefault) {
                            setBillingAddress(address.Id)
                            setShippingAddress(address.Id)
                        }
                        return true;
                    })

                    getProductCart(query).then(({ data }) => {
                        if (data.Status) {
                            if (data.Data.CartItems.length) {
                                setVoucher(data.Data.CouponCodes.filter(c=>c.CouponCode))
                                setCartItems(data.Data.CartItems)
                                eventCartItems.current = data.Data.CartItems
                                const isOutOfStock = data.Data.CartItems.filter(i => !i.Product.InStock)
                                const isAlreadyPurchased = data.Data.CartItems.filter(i => i.Product.IsAlreadyPurchased)
                                setOutOfStock(isOutOfStock.length ? true : false)
                                setIsNotAvailable(isAlreadyPurchased.length ? true : false)
                                setCartSummary({
                                    "TotalDiscountAmount": data.Data.TotalDiscountAmount,
                                    "OrderTaxTotal": data.Data.OrderTaxTotal,
                                    "SubTotal": data.Data.SubTotal,
                                    "TotalAmount": data.Data.TotalAmount,
                                    "ShippingTotal": data.Data.ShippingTotal,
                                })
                                setAvailablePayments(data.Data.AvailablePayments && data.Data.AvailablePayments.length ? data.Data.AvailablePayments : [])
                                setShowTamara(data.Data.AvailablePayments && data.Data.AvailablePayments.length ? true : false)
                                eventCartData.current = { "Discount": data.Data.TotalDiscountAmount, "SubTotal": data.Data.SubTotal, "TotalAmount": data.Data.TotalAmount }
                                
                                analyticsTrackEvent("Checkout Started", { 
                                    discount: data.Data.TotalDiscountAmount, 
                                    first_order: false, 
                                    item_count: data.Data.CartItems.length, 
                                    products: data.Data.CartItems, 
                                    revenue: 0,
                                    sub_total: data.Data.SubTotal, 
                                    total: data.Data.TotalAmount 
                                })
                                if(data.Data.ShowEmailUI){
                                    setEmailVisible(true);
                                    setEmailRequired(data.Data.IsEmailRequired);
                                }
                            }
                            else {
                                setApiFailed(<T>You currently have no products in your cart.</T>)
                            }
                        }
                        else {
                            setApiFailed(<T>You currently have no products in your cart.</T>)
                        }
                        setLoading(false)
                    }).catch((e) => {
                        console.log(e)
                        setLoading(false)
                        setApiFailed(<T>Something went wrong. Please try again later.</T>)
                    })
                }
                else {
                    setLoading(false)
                    setApiFailed(<T>You currently have no products in your cart.</T>)
                }
            }).catch((e) => {
                console.log(e)
                setLoading(false)
                setApiFailed(<T>Something went wrong. Please try again later.</T>)
            })
        }
        else {
            if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
                query = encodeURIComponent(`?q=${buyNowQuantity}&p=${buyNowProduct}`);
            }
            else{
                query = "";
            }
            history('/login?redirect=/checkout'+query);
        }
    }, [])

    useEffect(()=>{
        // if(checkout && !outOfStock){
        if(checkout){
            const buyNowQuantity = getQueryParamManual('q');
            const buyNowProduct = getQueryParamManual('p'); 
            let query = { buyNow : null};
            if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
                query.buyNow = { "productId": buyNowProduct, "quantity": buyNowQuantity }
            }
            const checkoutData = {
                "email": userInfo.Email,
                "cardDetails": tokenObject,
                "PaymentMethod": paymentMethod,
                ...(!addressUnknown && {"ShippingAddressId": shippingAddress}),
                "BillingAddressId": !addressUnknown ? sameBilling ? shippingAddress : billingAddress : billingAddress,
                "saveCard": storeCard,
                ...(query && query.buyNow && { ...query }),
                ...(addressUnknown && {"dontknowaddress": true}),
                ...(addressUnknown && {"addressDetails": {firstName : recipientFirstName, lastName: recipientLastName, phone: recipientPhoneNo}}),
                "CartItems": cartItems.map((i)=>{ return { ProductId: i.Product.Id, StockQuantity: i.Product.StockQuantity ? i.Product.StockQuantity : 0} })
            }
            cartCheckout(checkoutData).then(({ data }) => {
                if (data.Status) {
                    setApiResponse(data.Data)
                    setApiResponseStatus(true)
                    if(!query || !query.buyNow){
                        updateCartCount(0);
                    }
                }
                else {
                    if([2030].includes(data.StatusCode)){
                        let err = { "en": "", "ar": "" };
                        err[language] = data.Message;
                        setApiResponse(err)
                        setApiResponseStatus(false)
                    }
                    else if([1066,1063,1064,1041,1053,1033,1029,1018,1007].includes(data.StatusCode)){
                        setApiResponse(failureReasonTranslate(data.StatusCode))
                        setApiResponseStatus(false)
                    }
                    else if (data.Data) {
                        if (paymentMethod === "Payments.Manual" && data.Data.CheckoutResponse) {
                            if (data.Data.CheckoutResponse.href) {
                                setPopupConfirmation(true)
                                setCheckoutURL(data.Data.CheckoutResponse.href)
                                setApiResponseTemp(data.Data)
                                eventOrderData.current = data.Data
                            }
                            else{
                                setApiResponse(failureReasonTranslate(data.StatusCode))
                                setApiResponseStatus(false)
                            }
                        }
                        else {
                            setApiResponse(failureReasonTranslate(data.StatusCode))
                            setApiResponseStatus(false)
                        }
                    }
                    else {
                        setApiResponse(failureReasonTranslate(data.StatusCode))
                        setApiResponseStatus(false)
                    }
                }
                scrollToTop();
            }).catch((e) => {
                scrollToTop();
                console.log(e)
                setApiFailed(<T>Something went wrong. Please try again later.</T>)
            })
        }
    }, [checkout,tokenObject])

    const updateCartItems = () => {
        scrollToTop()
        const buyNowQuantity = getQueryParamManual('q');
        const buyNowProduct = getQueryParamManual('p'); 
        let query = { buyNow : null};
        if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
            query.buyNow = { "productId": buyNowProduct, "quantity": buyNowQuantity }
        }
        setShowTamara(false)
        getProductCart(query).then(({ data }) => {
            if (data.Status) {
                if (data.Data.CartItems.length) {
                    setVoucher(data.Data.CouponCodes.filter(c=>c.CouponCode))
                    setCartItems(data.Data.CartItems)
                    eventCartItems.current = data.Data.CartItems
                    const isOutOfStock = data.Data.CartItems.filter(i => !i.Product.InStock)
                    const isAlreadyPurchased = data.Data.CartItems.filter(i => i.Product.IsAlreadyPurchased)
                    setOutOfStock(isOutOfStock.length ? true : false)
                    setIsNotAvailable(isAlreadyPurchased.length ? true : false) 
                    setCartSummary({
                        "TotalDiscountAmount": data.Data.TotalDiscountAmount,
                        "OrderTaxTotal": data.Data.OrderTaxTotal,
                        "SubTotal": data.Data.SubTotal,
                        "TotalAmount": data.Data.TotalAmount,
                        "ShippingTotal": data.Data.ShippingTotal,
                    })
                    setAvailablePayments(data.Data.AvailablePayments && data.Data.AvailablePayments.length ? data.Data.AvailablePayments : [])
                    setShowTamara(data.Data.AvailablePayments && data.Data.AvailablePayments.length ? true : false)
                    eventCartData.current = { "Discount": data.Data.TotalDiscountAmount, "SubTotal": data.Data.SubTotal, "TotalAmount": data.Data.TotalAmount }
                    if(data.Data.ShowEmailUI){
                        setEmailVisible(true);
                        setEmailRequired(data.Data.IsEmailRequired);
                    }
                }
                else {
                    setApiFailed(<T>You currently have no products in your cart.</T>)
                    localStorage.setItem("occasion",0)
                    updateCartCount(0);
                    localStorage.removeItem("cartItemCount")
                }
            }
            else {
                setApiFailed(<T>You currently have no products in your cart.</T>)
            }
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            setLoading(false)
            setApiFailed(<T>Something went wrong. Please try again later.</T>)
        })
    }

    const formSubmit = (e) => {
        scrollToTop(400);
        setEmailError("");
        let flagCheckout = false;
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if (emailRequired || (userInfo.Email && userInfo.Email.length)) {
            if (!emailRegex.test(userInfo.Email)) {
                setEmailError(_T("Please provide a valid email address"));
                flagCheckout = true;
            }
        }
        if(!flagCheckout){
            Frames.submitCard();
        }
    }
    const updateBillingAddress = (e) => {
        if (e.target.checked) {
            setBillingAddress(e.target.value)
        }
    }
    const updateShippingAddress = (e) => {
        if (e.target.checked) {
            setShippingAddress(e.target.value)
            setAddressUnknown(false);
            if(!showTamara){
                setShowTamara(true);
            }
        }
    }

    const cardSelection = (e, card = null) => {
        if (e.target.checked && card === null) {
            setUseNewCard(e.target.value === "true" ? true : false)
            setPreviousCard(card)
        }
        else{
            setUseNewCard(false)
            setPreviousCard(card)
        }
    }
    const changeCardIcon = (e) => {
        if (e.scheme) {
            const src = e.scheme.toLowerCase().replaceAll(" ", "-")
            setSelectedCardIcon(cardIcons[src] ? cardIcons[src] : cardIcons["undefined"])
        }
        else {
            setSelectedCardIcon(cardIcons["undefined"])
        }
    }

    const previousCardCVVChange = (e,min) =>{
        const value = (e.target.value.replace(/[^0-9]/g, '')).slice(0,min)
        setPreviousCardCVV(value)
        setIsPreviousCardCVVValid(value.length === min)
        setShowPreviousCardCVVValid(value.length > 0)
    } 

    const confirmCVV = () => {
        setEmailError("");
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if (emailRequired || (userInfo.Email && userInfo.Email.length)) {
            if (!emailRegex.test(userInfo.Email)) {
                setEmailError(_T("Please provide a valid email address"));
            } else {
                setPopupCVV(true);
            }
        } else {
            setPopupCVV(true);
        }
    }

    const confirmCVVCheckout = (e) => {
        setPopupCVV(false)
        setCheckoutLoading(true)
        setStoreCard(false)
        setTokenObject(e)
        setCheckout(true)
    }

    const checkoutWithTamara = () => {
        scrollToTop();
        setEmailError("");
        let flagCheckout = false;
        const emailRegex = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if (userInfo.Email && userInfo.Email.length) {
            if (!emailRegex.test(userInfo.Email)) {
                setEmailError(_T("Please provide a valid email address"));
                flagCheckout = true;
            }
        }
        else{
            setEmailError(_T("You have to provide an email address to use Tamara"));
            flagCheckout = true;
        }
        if(!flagCheckout){
            const buyNowQuantity = getQueryParamManual('q');
            const buyNowProduct = getQueryParamManual('p'); 
            let query = { buyNow : null};
            if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
                query.buyNow = { "productId": buyNowProduct, "quantity": buyNowQuantity }
            }
            const checkoutData = {
                "email": userInfo.Email,
                "PaymentMethod": paymentMethod,
                ...(!addressUnknown && {"ShippingAddressId": shippingAddress}),
                "BillingAddressId": !addressUnknown ? sameBilling ? shippingAddress : billingAddress : billingAddress,
                ...(query && query.buyNow && { ...query }),
                ...(addressUnknown && {"dontknowaddress": true}),
                ...(addressUnknown && {"addressDetails": {firstName : recipientFirstName, lastName: recipientLastName, phone: recipientPhoneNo}}),
                "CartItems": cartItems.map((i)=>{ return { ProductId: i.Product.Id, StockQuantity: i.Product.StockQuantity ? i.Product.StockQuantity : 0} })
            }
            setCheckoutLoading(true);
            cartCheckout(checkoutData).then(({ data }) => {
                if (data.Status) {
                    if(data.Data.PaymentType && data.Data.PaymentType === "Tamara" && data.Data.CheckoutResponse && data.Data.CheckoutResponse && data.Data.CheckoutResponse.checkout_url){
                        setPopupConfirmation(true)
                        setIsTamara(true);
                        setCheckoutURL(data.Data.CheckoutResponse.checkout_url)
                        // generateTamaraWindow(data.Data.CheckoutResponse.checkout_url)
                        // setApiResponse(data.Data);
                        setApiResponseTemp(data.Data)
                        eventOrderData.current = data.Data
                    }
                    else{
                        setCheckoutLoading(false);
                        setApiResponse(data.Data);
                        setApiResponseStatus(true);
                        if(!query || !query.buyNow){
                            updateCartCount(0);
                        }
                    }
                }
                else {
                    if([2030].includes(data.StatusCode)){
                        let err = { "en": "", "ar": "" };
                        err[language] = data.Message;
                        setApiResponse(err)
                        setApiResponseStatus(false)
                    }
                    else if([1066,1063,1064,1041,1053,1033,1029,1018,1007].includes(data.StatusCode)){
                        setApiResponse(failureReasonTranslate(data.StatusCode))
                        setApiResponseStatus(false)
                    }
                    else if (data.Data) {
                        if (paymentMethod === "Payments.Manual" && data.Data.CheckoutResponse) {
                            if (data.Data.CheckoutResponse.href) {
                                setPopupConfirmation(true)
                                setCheckoutURL(data.Data.CheckoutResponse.href)
                                setApiResponseTemp(data.Data)
                                eventOrderData.current = data.Data
                            }
                            else{
                                setApiResponse(failureReasonTranslate(data.StatusCode))
                                setApiResponseStatus(false)
                                setCheckoutLoading(true);
                            }
                        }
                        else {
                            setApiResponse(failureReasonTranslate(data.StatusCode))
                            setApiResponseStatus(false)
                            setCheckoutLoading(true);
                        }
                    }
                    else {
                        setApiResponse(failureReasonTranslate(data.StatusCode))
                        setApiResponseStatus(false)
                        setCheckoutLoading(true);
                    }
                }
                scrollToTop();
            }).catch((e) => {
                scrollToTop();
                setApiFailed(_T("Something went wrong. Please try again later."))
            })
        }
    }

    const onFrameValidationChanged = (e) => {
        switch (e.element) {
            case "card-number":
                setIsCardNumberValid(e.isValid)
                setShowCardNumberError(!e.isEmpty)
                break;
            case "expiry-date":
                setIsCardExpiryValid(e.isValid)
                setShowExpiryError(!e.isEmpty)
                break;
            case "cvv":
                setIsCardCVCValid(e.isValid)
                setShowCVCError(!e.isEmpty)
                break;
            default:
                break;
        }
    }

    window.addEventListener("message",(event) => {
        if(event.data.type === "tamara_checkout_message"){
            if(["ORDER_CANCELED","ORDER_FAILED"].includes(event.data.event)){
                if(tamaraActionPending.current){
                    tamaraActionPending.current = false
                    setTimeout(() => { setCheckoutURL(event.data.data.callbackUrl); }, 5000);
                    setMinimize(true);
                }
            }
            if(["ORDER_SUCCESS"].includes(event.data.event)){
                if(tamaraActionPending.current){
                    tamaraActionPending.current = false
                    setTimeout(() => { setCheckoutURL(event.data.data.callbackUrl); }, 5000);
                    setMinimize(true);
                }
            }
        }
        if(event.data.event === "cardTokenized" && !checkout){
            setCheckoutLoading(true)
            if(event.data.data.type === "card" && event.data.data.token.length > 5){
                setTokenObject(event.data.data)
                setCheckout(true)
            }
            else{
                setApiResponse(failureReasonTranslate(1029))
                setApiResponseStatus(false)
            }
        }
        if(event.data.type === "checkout-payment-status"){
            scrollToTop();
            if(event.data.output === "success"){
                if(!eventExecuted.current){
                    eventExecuted.current = true;
                    const sa = addresses.find((a)=> a.Id == shippingAddress);
                    const customer = userRef.current.FullName ? userRef.current.FullName.toLowerCase().split(" ") : ["NA","NA"]
                    analyticsTrackEvent("Checkout Completed", {discount: eventCartData.current.Discount,first_order: false, item_count: eventCartItems.current.length, order_number: 0, product_ids: eventCartItems.current.map((p)=>p.Product.Id),products: eventCartItems.current, revenue: 0,...(!addressUnknown && sa && {shipping_method: sa.HouseNo+", "+sa.StreetAddress+", "+sa.CityName+", "+sa.RegionName+", "+sa.CountryName+", "+sa.ZipCode }),...(addressUnknown && {shipping_method: recipientFirstName+" "+recipientLastName+", "+recipientPhoneNo}),...(addressUnknown && { customer_email: userRef.current.Email, customer_phone: recipientPhoneNo, customer_first_name: recipientFirstName ? recipientFirstName.toLowerCase() : "NA", customer_last_name: recipientLastName ? recipientLastName.toLowerCase() : "NA", customer_city: "NA", customer_state: "NA", customer_country: "NA", customer_zip: "NA" }),...(!addressUnknown && sa && { customer_email: userRef.current.Email, customer_phone: userRef.current.Phone ? userRef.current.Phone : "00000000000", customer_first_name: customer[0] ? customer[0] : "NA", customer_last_name:  customer[1] ? customer[1] : "NA", customer_city: sa.CityName, customer_state: sa.RegionName, customer_country: sa.CountryName, customer_zip: sa.ZipCode }),sub_total: eventCartData.current.SubTotal, total: eventCartData.current.TotalAmount })
                    analyticsTrackEvent("Order Completed", { order_id: eventOrderData.current.Id,discount: eventCartData.current.Discount, first_order: false, item_count: eventCartItems.current.length, order_number: eventOrderData.current.OrderNumber, products: eventCartItems.current, status: "Pending", revenue: 0, ...(!addressUnknown && sa && {shipping_method: sa.HouseNo+", "+sa.StreetAddress+", "+sa.RegionName+", "+sa.CountryName+", "+sa.ZipCode }), ...(addressUnknown && {shipping_method: recipientFirstName+" "+recipientLastName+", "+recipientPhoneNo}), sub_total: eventCartData.current.SubTotal, total: eventCartData.current.TotalAmount })
                    twitterConversion({ value: eventCartData.current.TotalAmount, currency: "SAR", contents: eventCartItems.current.map((item)=>({ content_type: "product", content_id: item.ProductId, content_name: item.Product.Name, content_price: item.Product.Total, num_items: item.Quantity, })), conversion_id: eventOrderData.current.Id, email_address: userInfo.Email, phone_number: userInfo.Phone })
                    tiktokConversion('Checkout Completed',{ value: eventCartData.current.TotalAmount, currency: "SAR", contents: eventCartItems.current.map((item)=>({ content_type: "product", content_id: item.ProductId, content_name: item.Product.Name, content_price: item.Product.Total, num_items: item.Quantity, })), conversion_id: eventOrderData.current.Id, email_address: userInfo.Email, phone_number: userInfo.Phone })
                    tiktokConversion('PlaceAnOrder',{ value: eventCartData.current.TotalAmount, currency: "SAR", contents: eventCartItems.current.map((item)=>({ content_type: "product", content_id: item.ProductId, content_name: item.Product.Name, content_price: item.Product.Total, num_items: item.Quantity, })), conversion_id: eventOrderData.current.Id, email_address: userInfo.Email, phone_number: userInfo.Phone })
                }
                setApiResponseStatus(true)
                setApiResponse(apiResponseTemp)
                const buyNowQuantity = getQueryParamManual('q');
                const buyNowProduct = getQueryParamManual('p'); 
                let query = { buyNow : null};
                if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
                    query.buyNow = { "productId": buyNowProduct, "quantity": buyNowQuantity }
                }
                if(!query || !query.buyNow){
                    updateCartCount(0);
                    localStorage.removeItem("cartItemCount")
                }
            }
            else{
                setApiResponse(failureReasonTranslate(1029))
                setApiResponseStatus(false)
            }
            setCheckoutLoading(false)
            setPopupConfirmation(false)
        }
    }, false);

    const validateFirstName = (v) => {
        const value = v
        setRecipientFirstName(value)
        if (!value || value.replaceAll(/\s/g,'').length < 2) {
            setRecipientFirstNameError(true)
        } 
        // else if (!/^[A-Za-z\s]*$/.test(value)) {
        //     setRecipientFirstNameError(true)
        // }
        else{
            setRecipientFirstNameError(false)
        }
    }
    const validateLastName = (v) => {
        const value = v
        setRecipientLastName(value)
        if (!value || value.replaceAll(/\s/g,'').length < 2) {
            setRecipientLastNameError(true)
        } 
        // else if (!/^[A-Za-z\s]*$/.test(value)) {
        //     setRecipientLastNameError(true)
        // }
        else{
            setRecipientLastNameError(false)
        }
    }
    const validatePhone = (v) => {
        const value = v
        setRecipientPhoneNo(value)
        if (!value || value.replaceAll(/\s/g,'').length < 9 || value.replaceAll(/\s/g,'').length > 12) {
            setRecipientPhoneNoError(true)
        }
        else{
            setRecipientPhoneNoError(false)
        }
    }

    useEffect(()=>{
        if(cartUpdate){
            const buyNowQuantity = getQueryParamManual('q');
            const buyNowProduct = getQueryParamManual('p'); 
            let query = {};
            if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
                query = { "productId":buyNowProduct, "quantity":buyNowQuantity }
            }
            setShowTamara(false)
            getProductCart(query).then(({ data }) => {
                if (data.Status) {
                    if (data.Data.CartItems.length) {
                        setVoucher(data.Data.CouponCodes.filter(c=>c.CouponCode))
                        setCartItems(data.Data.CartItems)
                        eventCartItems.current = data.Data.CartItems
                        const isOutOfStock = data.Data.CartItems.filter(i => !i.Product.InStock)
                        const isAlreadyPurchased = data.Data.CartItems.filter(i => i.Product.IsAlreadyPurchased)
                        setOutOfStock(isOutOfStock.length ? true : false)
                        setIsNotAvailable(isAlreadyPurchased.length ? true : false)
                        setCartSummary({
                            "TotalDiscountAmount": data.Data.TotalDiscountAmount,
                            "OrderTaxTotal": data.Data.OrderTaxTotal,
                            "SubTotal": data.Data.SubTotal,
                            "TotalAmount": data.Data.TotalAmount,
                            "ShippingTotal": data.Data.ShippingTotal,
                        })
                        setAvailablePayments(data.Data.AvailablePayments && data.Data.AvailablePayments.length ? data.Data.AvailablePayments : [])
                        setShowTamara(data.Data.AvailablePayments && data.Data.AvailablePayments.length ? true : false)
                        eventCartData.current = { "Discount": data.Data.TotalDiscountAmount, "SubTotal": data.Data.SubTotal, "TotalAmount": data.Data.TotalAmount }
                        analyticsTrackEvent("Checkout Started", { discount: data.Data.TotalDiscountAmount, first_order: false, item_count: data.Data.CartItems.length, products: data.Data.CartItems, revenue: 0, sub_total: data.Data.SubTotal, total: data.Data.TotalAmount })
                        if(data.Data.ShowEmailUI){
                            setEmailVisible(true);
                            setEmailRequired(data.Data.IsEmailRequired);
                        }
                    }
                    else {
                        setApiFailed(<T>You currently have no products in your cart.</T>)
                    }
                }
                else {
                    setApiFailed(<T>You currently have no products in your cart.</T>)
                }
                setLoading(false)
            }).catch((e) => {
                console.log(e)
                setLoading(false)
                setApiFailed(<T>Something went wrong. Please try again later.</T>)
            })
        }
    },[cartUpdate])

    const removeCoupon = (c) => {
        setUpdating(true)
        setLoadingCoupon(true)
        const buyNowQuantity = getQueryParamManual('q');
        const buyNowProduct = getQueryParamManual('p'); 
        let query = "";
        if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
            query = `&productId=${buyNowProduct}&quantity=${buyNowQuantity}`;
        }
        removeCouponCode("?discountCode="+c+query).then(({data})=>{
            if(data.Status){
                analyticsTrackEvent("User Removed Promo Code", { promo_code: c })
                setCouponCode("")
                setCartUpdate(cartUpdate+1)
            }
            setUpdating(false)
            setLoadingCoupon(false)
        }).catch((e)=>{
            setCouponCode("")
            setUpdating(false)
        })
    }

    const validateCouponSubmit = (e) => {
        let error = "";
        let flag = false;
        if (!couponCode || couponCode.replaceAll(/\s/g, '').length < 2) {
            error = <T>Please provide a valid Coupon.</T>;
            flag = true
        }
        setCouponError(error);
        if(!flag){
            setUpdating(true)
            setLoadingCoupon(true)
            const buyNowQuantity = getQueryParamManual('q');
            const buyNowProduct = getQueryParamManual('p'); 
            let query = "";
            if(buyNowQuantity && buyNowProduct && !isNaN(buyNowQuantity) && !isNaN(buyNowProduct)){
                query = `&productId=${buyNowProduct}&quantity=${buyNowQuantity}`;
            }
            applyCoupon("?discountCode="+couponCode+query).then(({data})=>{
                if(data.Status){
                    analyticsTrackEvent("User Redeem Promo Code", { promo_code: couponCode })
                }
                else{
                    error = couponErrorCodes[data.StatusCode] ? couponErrorCodes[data.StatusCode][language] : <T>Please provide a valid Coupon.</T>
                    setCouponError(error);
                }
                setCartUpdate(cartUpdate+1)
                setCouponCode("")
                setLoadingCoupon(false)
                setUpdating(false)
            }).catch((e)=>{
                setCouponCode("")
                setLoadingCoupon(false)
                error = _T("Please provide a valid Coupon");
                setCouponError(error);
                setUpdating(false)
            })
        }
    }

    useEffect(()=>{
        if(showTamara && availablePayments && availablePayments.length && config.EnableTamara){
            if(window.tamaraWidgetConfig){
                window.tamaraWidgetConfig.lang = language;
                window.TamaraWidgetV2.refresh();
            }
        }
    },[showTamara,config.EnableTamara])

    const generateCheckoutOption = () => {
        const hasAddressError = addressUnknown ? recipientFirstNameError || recipientLastNameError || recipientPhoneNoError || billingAddress === 0 : shippingAddress === 0;
        const isTamara = paymentMethod === "Tamara" || paymentMethod === "تمارا";
        const hasCardError = !isTamara && useNewCard ? !(isCardNumberValid && isCardExpiryValid && isCardCVCValid) : false;
        const disabled = hasAddressError || !conditionChecked || hasCardError || outOfStock || isNotAvailable || ( (paymentMethod === "Tamara" || paymentMethod === "تمارا") && addressUnknown );
        if(checkoutLoading){
            return <button className={`btn ${ updating || disabled ? "btn-disabled" : "btn-primary" } w-100`} type="button"><T>Please Wait...</T></button>
        }
        else{
            if(paymentMethod === "Tamara" || paymentMethod === "تمارا"){
                if(availablePayments && availablePayments.length){
                    return <button disabled={updating || disabled} className={`btn ${ updating || disabled ? "btn-disabled" : "btn-primary" } w-100`} type="button" onClick={()=>{checkoutWithTamara()}}><T>Pay with Tamara</T></button>
                }
                else{
                    return <button disabled={true} className={`btn btn-disabled w-100`} type="button" onClick={()=>{}}><T>Pay with Tamara</T></button>
                }
            }
            else if(!useNewCard){
               return <button disabled={updating || disabled} className={`btn ${ updating || disabled ? "btn-disabled" : "btn-primary" } w-100`} type="button" onClick={()=>{confirmCVV()}}><T>Pay</T> SAR {cartSummary.TotalAmount.toFixed(2)}</button>
            }
            else{
                return <button disabled={updating || disabled} className={`btn ${ updating || disabled ? "btn-disabled" : "btn-primary" } w-100`} type="button" onClick={()=>{formSubmit()}}><T>Pay</T> SAR {cartSummary.TotalAmount.toFixed(2)}</button>
            }
        }
    };

    return (
        <>
            <BreadCrumbs pageTile="Checkout" breadcrumbItems={breadcrumbItems} />
            {
                cartError ?
                <div className="checkout-cart-error">
                    <div className="text-center">
                        <LazyLoadImage src={failedIcon} width="100" alt="Payment Failed"/>
                        <h1 className="text-primary fw-500 fs-24"><T>Oops!</T></h1>
                        <p className="text-gray-400 fw-400 fs-16">{cartError[language]}</p>
                        <Link to={"/cart"} className="btn btn-outline-primary"><T>Go to Cart</T></Link>
                    </div>
                </div> : <></>
            }
            {
                popupConfirmation ?
                <div id="PopupConfirmation" className={`${isTamara ? "isTamara" : ""} ${minimize ? "minimize" : ""}`}>
                    <div className="popupConfirmation-overlay"></div>
                    <div className="popupConfirmation-container">
                        <div className="popupConfirmation-close"><i className="ri-close-line" onClick={()=>{setPopupConfirmation(false); setApiResponse(failureReasonTranslate(1029)); setApiResponseStatus(false); }}></i></div>
                        <iframe src={checkoutURL} title="Payment Confirmation"></iframe>
                    </div>
                </div> : <></>
            }
            {
                !cartError ?
                    apiResponse === null ?
                        !checkoutLoading ?
                            <section id="checkout">
                                <div className="wrapper">
                                    <div className="section-header w-100">
                                        <h1 className="fs-24 text-gray-500 fw-500 mt-0 mb-0"><T>Checkout</T></h1>
                                        <p className="fs-16 text-gray-400 fw-400 mt-0 mb-0"><T>Following are all the checkout details.</T></p>
                                    </div>
                                    {
                                        loading ?
                                            <p className="text-secondary btn btn-alternative w-100"><T>Loading checkout options. Please Wait...</T></p> :
                                            apiFailed ?
                                            <p className="text-secondary btn btn-alternative w-100"><i className="ri-error-warning-line mr-2 fs-22"></i> {apiFailed}</p> :
                                            <form className="d-flex justify-between flex-wrap" onSubmit={(e)=>{ e.preventDefault(); }}>
                                                <div className="left-checkout">
                                                    <div className="personal-information">
                                                        <div className="card">
                                                            <div className="card-heading">
                                                                <T tagName={"h2"} className="fs-20 fw-500 text-gray-500 border-line d-inline-block mt-0">Personal Information</T>
                                                            </div>
                                                            <div className="d-flex justify-between flex-wrap">
                                                                {/* <div className="checkout-form-content mt-2">
                                                                    <label className="fw-500 fs-14 m-0 ">{translations.checkOut[language].nameLabel} <span className="text-primary">*</span></label>
                                                                    <input id="name" type="text" name="name" readOnly={true} value={userInfo.FullName} placeholder={translations.checkOut[language].fullName} />
                                                                </div> */}
                                                                <div className="checkout-form-content mt-2">
                                                                    <label className="fw-500 fs-14 m-0 "><T>Phone Number</T><span className="text-primary">*</span></label>
                                                                    <input id="phoneno" type="text" name="phoneno" readOnly={true} value={userInfo.Phone ? userInfo.Phone : "" } placeholder="e.g +971 55 487 2817" />
                                                                </div>
                                                                {
                                                                    emailVisible ?
                                                                    <div className="checkout-form-content mt-2">
                                                                        <label className="fw-500 fs-14 m-0 "><T>Email</T> { emailRequired ? <span className="text-primary">*</span> : null}</label>
                                                                        <input id="email" type="text" name="email" readOnly={!enableEmail} value={userInfo.Email ? userInfo.Email : ""} onChange={(e)=> {setUserInfo((prev)=>({...prev,Email: e.target.value.slice(0,50)})); setEmailError("");} } placeholder="example@mail.com" />
                                                                        { emailError ? <p className="text-primary fs-12 fw-500 m-1 mb-0">{emailError}</p> : null }
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="shipping-information">
                                                        <div className="card">
                                                            <div className="card-heading d-flex justify-between align-items-center">
                                                                <T tagName={"h2"} className="fs-20 fw-500 text-gray-500 border-line d-inline-block mt-0">Shipping Information</T>
                                                                {
                                                                    !addressUnknown && addresses.length ?
                                                                    <div className="d-flex align-items-center ">
                                                                        <input id="sameBilling" type={"checkbox"} value={true} checked={sameBilling} onChange={(e) => { e.target.checked ? setSameBilling(true) : setSameBilling(false) }} className="input-checkbox" />
                                                                        <label htmlFor="sameBilling" className="fs-14 fw-500 text-gray-500"><T>Use same billing address</T></label>
                                                                    </div> : <></>
                                                                }
                                                            </div>
                                                            <div className="d-flex justify-between flex-wrap">
                                                                {
                                                                    addresses.length ?
                                                                    addresses.map((item, i) => {
                                                                        return <div key={i} className="checkout-address-card">
                                                                            <input id={"shippingAddress" + i} className="input-radio" type={"radio"} onChange={updateShippingAddress} value={item.Id} defaultChecked={item.IsDefault} name="shippingAddress" />
                                                                            <label htmlFor={"shippingAddress" + i} key={i}>
                                                                                <div>
                                                                                    <h3 className="text-secondary fs-14 fw-500 mt-0 mb-2 text-capitalize">{addressTitles[item.Title.toLowerCase()] ? addressTitles[item.Title.toLowerCase()][language] : item.Title} <T>Address</T></h3>
                                                                                    <p className="fw-500 text-gray-500 fs-14 m-0 d-inline-block">{item.HouseNo}, {item.StreetAddress}, {item.CityName}, {item.RegionName}, {item.ZipCode}</p>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    }):
                                                                    <Link to={"/account/addresses"} className="btn btn-alternative w-100 mb-3"><i className="ri-error-warning-line fs-24 mr-2"></i><T>You currently have no addresses saved.</T><b className="ml-1"><T>Create Now</T></b></Link>
                                                                }
                                                                <div className="checkout-address-card">
                                                                    <input id={"shippingAddress000"} className="input-radio" type={"radio"} onChange={(e)=> { if(e.target.checked){ setAddressUnknown(true); setShowTamara(false); setSameBilling(false); } else{ setAddressUnknown(false); setShowTamara(true); setSameBilling(false); } }} checked={addressUnknown} value={"unknown"} name="shippingAddress" />
                                                                    <label htmlFor={"shippingAddress000"}>
                                                                        <div>
                                                                            <h3 className="text-secondary fs-14 fw-500 mt-0 mb-2"><T>Send as gift</T></h3>
                                                                            <p className="fw-500 text-gray-500 fs-14 m-0 d-inline-block"><T>We will contact the recipient to find out the address</T></p>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                { 
                                                                    addressUnknown ?
                                                                    <div className="d-flex justify-between flex-wrap"> 
                                                                        <div className="checkout-form-content mt-2">
                                                                            <label className="fw-500 fs-14 m-0 "><T>Recipient First Name</T> <span className="text-primary">*</span></label>
                                                                            <input id="recipientfirstName" type="text" name="recipientfirstName" onChange={(e)=>{validateFirstName(e.target.value)}} value={recipientFirstName} placeholder={_T("Recipient First Name")} />
                                                                            { recipientFirstNameError && recipientFirstName.length ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Please provide recipient's first name</T></span> : <></>}
                                                                        </div>
                                                                        <div className="checkout-form-content mt-2">
                                                                            <label className="fw-500 fs-14 m-0 "><T>Recipient Last Name</T> <span className="text-primary">*</span></label>
                                                                            <input id="recipientlastName" type="text" name="recipientlastName" onChange={(e)=>{validateLastName(e.target.value)}} value={recipientLastName} placeholder={_T("Recipient Last Name")} />
                                                                            { recipientLastNameError && recipientLastName.length ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Please provide recipient's last name</T></span> : <></>}
                                                                        </div>
                                                                        <div className="checkout-form-content mt-2 w-100">
                                                                            <label className="fw-500 fs-14 m-0 "><T>Recipient Phone Number</T> <span className="text-primary">*</span></label>
                                                                            <input id="recipientphoneNumber" type="tel" name="recipientphoneNumber" onChange={(e)=>{validatePhone(e.target.value.replace(/\D/g, ''))}} value={recipientPhoneNo} placeholder={_T("Recipient Phone Number")} />
                                                                            { recipientPhoneNoError && recipientPhoneNo.length ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Please provide recipient's phone number</T></span> : <></>}
                                                                        </div>
                                                                    </div>
                                                                    : <></>
                                                                }
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        sameBilling ? <></> :
                                                            <div className="billing-information">
                                                                <div className="card">
                                                                    <div className="card-heading">
                                                                        <h2 className="fs-20 fw-500 text-gray-500 border-line d-inline-block mt-0"><T>Billing Information</T></h2>
                                                                    </div>
                                                                    <div className="d-flex justify-between flex-wrap">
                                                                        {
                                                                            addresses.length ?
                                                                            addresses.map((item, i) => {
                                                                                return <div key={i} className="checkout-address-card">
                                                                                    <input id={"billingAddress" + i} className="input-radio" type={"radio"} onChange={updateBillingAddress} value={item.Id} defaultChecked={item.IsDefault} name="billingAddress" />
                                                                                    <label htmlFor={"billingAddress" + i} key={i}>
                                                                                        <div>
                                                                                            <h3 className="text-secondary fs-14 fw-500 mt-0 mb-2">{addressTitles[item.Title.toLowerCase()] ? addressTitles[item.Title.toLowerCase()][language] : item.Title} <T>Address</T></h3>
                                                                                            <p className="fw-500 text-gray-500 fs-14 m-0 d-inline-block">{item.HouseNo}, {item.StreetAddress}, {item.CityName}, {item.RegionName}, {item.ZipCode}</p>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            }) :
                                                                            <Link to={"/account/addresses"} className="btn btn-alternative w-100 mb-3"><i className="ri-error-warning-line fs-24 mr-2"></i><T>You currently have no addresses saved.</T><b className="ml-1"><T>Create Now</T></b></Link>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                    <div className="payment-method">
                                                        <div className="card">
                                                            <div className="card-heading">
                                                                <h2 className="fs-20 fw-500 text-gray-500 border-line d-inline-block mt-0 mb-4"><T>Payment Method</T></h2>
                                                            </div>
                                                            <div className="toggle-btn mb-2">
                                                                {
                                                                    paymentMethods.map((option, index) => {
                                                                        return option.SystemName === "Tamara" || option.SystemName === "تمارا" ? config.EnableTamara ? <span key={index} className="mr-3"><input onChange={() => { setTabShow(index); setPaymentMethod(option.SystemName) }} className="input-radio" type="radio" name="paymentOption" value={option.SystemName} id={"paymentOption" + index} checked={showTab === index} /><label className="fw-500 fs-14" htmlFor={"paymentOption" + index}>{option.Name}</label></span> : null :  <span key={index} className="mr-3"><input onChange={() => { setTabShow(index); setPaymentMethod(option.SystemName) }} className="input-radio" type="radio" name="paymentOption" value={option.SystemName} id={"paymentOption" + index} checked={showTab === index} /><label className="fw-500 fs-14" htmlFor={"paymentOption" + index}>{option.Name}</label></span>
                                                                    })
                                                                }
                                                            </div>
                                                            {
                                                                paymentMethods.map((option, index) => {
                                                                    return <div key={index} className={showTab === index ? "payment-option active" : "payment-option"}>
                                                                        {
                                                                            option.SystemName === "Payments.CashOnDelivery" ?
                                                                            <p className="btn btn-alternative w-100"><T>Pay cash on delivery</T></p> :
                                                                            option.SystemName === "Payments.Manual" ?
                                                                                <div className="card-payment-options">
                                                                                    <div className="previous-cards">
                                                                                        {
                                                                                            previousCards.map((card, index) => {
                                                                                                return <label key={index} className="single-payment-card d-block" htmlFor={"previousCardOption"+index}>
                                                                                                    <input className="input-radio" id={"previousCardOption"+index} name="selectedCard" type={"radio"} value={false} checked={previousCard && previousCard.CardId === card.CardId ? true : false } onChange={(e) => cardSelection(e, card)} /><label htmlFor={"previousCardOption"+index} className="fs-16 fw-500 text-gray-500">{card.Scheme}  ****  {card.Last4}</label>
                                                                                                    <p className="selected-card-expiry text-gray-400 fw-400 fs-14 mt-0 mb-0"><T>Expires On </T><span className="d-inline-flex">{card.ExpiryMonth}</span><span className="d-inline-flex">/</span><span className="d-inline-flex">{card.ExpiryYear.toString().slice(-2)}</span></p>
                                                                                                    <LazyLoadImage className="card-icon" src={cardIcons[card.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[card.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                                                                                                </label>
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            <label htmlFor="newCardOption" className="single-payment-card new-payment-card d-block">
                                                                                                <input className="input-radio" id="newCardOption" name="selectedCard" type={"radio"} value={true} checked={useNewCard} onChange={(e) => cardSelection(e)} /><label htmlFor="newCardOption"><T>Use New Card</T></label>
                                                                                            </label>
                                                                                        }
                                                                                    </div>
                                                                                    <div className={useNewCard ? "new-card" : "new-card d-none"}>
                                                                                        <h3 className="fs-16 fw-500 text-gray-500 d-inline-block mt-0 mb-0"><T>New Card</T></h3>
                                                                                        <p className="fs-14 fw-400 text-gray-400 mt-0 mb-2"><T>Please provide the following information</T></p>
                                                                                        <Frames
                                                                                            config={{
                                                                                                publicKey: process.env.REACT_APP_CHECKOUT_PUBLIC_KEY,
                                                                                                debugMode: true,
                                                                                                localization: {
                                                                                                cardNumberPlaceholder: !isArabic ? "Card Number" : "رقم البطاقة",
                                                                                                expiryMonthPlaceholder: 'MM',
                                                                                                expiryYearPlaceholder: 'YY',
                                                                                                cvvPlaceholder: 'CVV',
                                                                                                },
                                                                                                style: {
                                                                                                    base: {
                                                                                                        fontSize: '14px',
                                                                                                        width: "100%",
                                                                                                        margin: "0px",
                                                                                                        border: "1px solid #d6d5d5",
                                                                                                        padding: "0px 12px",
                                                                                                        outline: "none",
                                                                                                        boxShadow: "none",
                                                                                                        borderRadius: "5px",
                                                                                                        background: "#fff",
                                                                                                        color: "#393939",
                                                                                                        height: "42px;",
                                                                                                        textAlign: !isArabic ? "left":"right"
                                                                                                    },
                                                                                                    autofill: {
                                                                                                        background: "none",
                                                                                                    },
                                                                                                    hover: {
                                                                                                        outline: "none",
                                                                                                        color: "#393939",
                                                                                                    },
                                                                                                    focus: {
                                                                                                        outline: "none",
                                                                                                        color: "#393939",
                                                                                                        border: "1px solid #ED378F",
                                                                                                    },
                                                                                                    valid: {
                                                                                                        outline: "none",
                                                                                                        color: "#393939",
                                                                                                    },
                                                                                                    invalid: {
                                                                                                        outline: "none",
                                                                                                        border: "1px solid #ED378F",
                                                                                                        color: "#ED378F",
                                                                                                    },
                                                                                                    placeholder: {
                                                                                                        base: {
                                                                                                        color: "#595959",
                                                                                                        fontSize: '14px',
                                                                                                        fontWeight: "600",
                                                                                                        textAlign: !isArabic ? "left":"right"
                                                                                                        },
                                                                                                        focus: {
                                                                                                        color: "#444",
                                                                                                        border: "1px solid #d6d5d5",
                                                                                                        },
                                                                                                    },
                                                                                                },
                                                                                            }}
                                                                                            ready={() => {}}
                                                                                            frameActivated={(e) => {}}
                                                                                            frameFocus={(e) => {}}
                                                                                            frameBlur={(e) => {}}
                                                                                            frameValidationChanged={onFrameValidationChanged}
                                                                                            paymentMethodChanged={(e) => {}}
                                                                                            cardSubmitted={() => {}}
                                                                                            // cardTokenized={(e) => { console.log(e); finishCheckout(e)}}
                                                                                            cardTokenizationFailed={(e) => {}}
                                                                                            cardBinChanged={(e) => {changeCardIcon(e)}}
                                                                                        >
                                                                                            <div className="d-flex justify-between flex-wrap">
                                                                                                <div className="checkout-form-content mt-2 w-100 position-relative">
                                                                                                    <label className="fw-500 fs-14 m-0 ">{!isArabic ? "Card Number":"رقم البطاقة"} <span className="text-primary">*</span></label>
                                                                                                    <CardNumber/>
                                                                                                    <div className="card-icon"><LazyLoadImage src={selectedCardIcon} alt=""/></div>
                                                                                                    { showCardNumberError && !isCardNumberValid ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Card number not valid</T></span> : <></> }
                                                                                                </div>
                                                                                                <div className="checkout-form-content mt-2">
                                                                                                    <label className="fw-500 fs-14 m-0 ">{!isArabic ? "Card Expiry":"انتهاء صلاحية البطاقة"} <span className="text-primary">*</span></label>
                                                                                                    <ExpiryDate/>
                                                                                                    { showExpiryError && !isCardExpiryValid ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Card expiry not valid</T></span> : <></> }
                                                                                                </div>
                                                                                                <div className="checkout-form-content mt-2">
                                                                                                    <label className="fw-500 fs-14 m-0 ">{!isArabic ? "Card CVV/CVC":"بطاقة CVV / CVC"} <span className="text-primary">*</span></label>
                                                                                                    <Cvv/>
                                                                                                    { showCVCError && !isCardCVCValid ? <span className="d-flex align-items-center text-primary fs-12 fw-500 mt-2"><i className="ri-information-line mr-1 fs-16"></i><T>Card CVV/CVC not valid</T></span> : <></> }
                                                                                                </div>
                                                                                            </div>
                                                                                        </Frames>
                                                                                        <div className="save-consent d-flex align-items-center mt-3"><input type={"checkbox"} className="input-checkbox" name="saveConsent" id="saveConsent" value={true} checked={storeCard} onChange={(e) => { e.target.checked ? setStoreCard(true) : setStoreCard(false) }} /><label className="fs-14 fw-500 text-gray-500" htmlFor="saveConsent"><T>Save card for future purchases</T></label></div>
                                                                                    </div>
                                                                                </div>:
                                                                            option.SystemName === "Tamara" || option.SystemName === "تمارا" ? 
                                                                                !addressUnknown ?
                                                                                    (emailVisible || (!emailVisible && userInfo.Email && userInfo.Email.length)) && showTamara && availablePayments && availablePayments.length ? 
                                                                                    <tamara-widget type="tamara-summary" data-lang={language} amount={cartSummary.TotalAmount} inline-type='6' inline-variant='outlined' config='{"badgePosition":"","showExtraContent":"full"}'> </tamara-widget>
                                                                                    : <p className="btn btn-alternative m-0 w-100 mt-4"><T>Your account is not eligible for Tamara payments.</T> <a href="https://support.tamara.co/hc/en-us/articles/14126895776274-Why-can-t-I-find-one-of-Tamara-s-payment-solutions-at-checkout" target="_blank" rel="noreferrer" className="text-secondary mx-2 decoration-none"><T>Learn More!</T></a></p> 
                                                                                : <T tagName={"p"} className="btn btn-alternative m-0 w-100 mt-4">Tamara payments are disabled since it requires a complete shipping address.</T>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-checkout">
                                                    <div className="card">
                                                        <div className="checkout-product-heading">
                                                            <h2 className="text-gray-500 fs-20 fw-500 mt-0 border-line d-inline-block"><T>Shopping Cart</T></h2>
                                                            <div id="checkoutProducts">
                                                                {
                                                                    cartItems.map((item, index) => (
                                                                        <CheckoutProduct key={index}  index={index} item={item} methodDelete={updateCartItems} />
                                                                    ))
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="cart-summary">
                                                            <div className="cart-amounts">
                                                                <div className="d-flex justify-between mb-3 mt-3">
                                                                    <span className="fw-500 fs-16 text-gray-500 "><T>Sub Total:</T><br /> <span className="fs-12 text-gray-300"><T>Inclusive of VAT</T> </span></span>
                                                                    <span className="fw-500 fs-16 text-gray-400">SAR {cartSummary.SubTotal.toFixed(2)}</span>
                                                                </div>
                                                                {
                                                                    cartSummary.OrderTaxTotal ?
                                                                    <div className="d-flex justify-between mb-3">
                                                                            <span className="fw-500 fs-16 text-gray-500 "><T>Tax</T> ({<T>VAT</T>}):</span>
                                                                            <span className="fw-500 fs-16 text-gray-400">SAR {cartSummary.OrderTaxTotal.toFixed(2)}</span>
                                                                        </div>
                                                                        : <></>
                                                                    }
                                                                <div className="d-flex justify-between mb-3">
                                                                    <span className="fw-500 fs-16 text-gray-500 "><T>Shipping:</T></span>
                                                                    <span className="fw-500 fs-16 text-gray-400">{cartSummary.ShippingTotal ? "+ SAR " + cartSummary.ShippingTotal.toFixed(2) : "Free"}</span>
                                                                </div>

                                                                    {
                                                                        cartSummary.TotalDiscountAmount ?
                                                                            <div className="d-flex justify-between mt-3">
                                                                                <span className="fw-500 fs-16 text-gray-500 "><T>Discount:</T></span>
                                                                                <span className="fw-500 fs-16 text-primary">- SAR {cartSummary.TotalDiscountAmount.toFixed(2)}</span>
                                                                            </div> : <></>
                                                                    }
                                                            </div>
                                                            <div className="border-dotted-light mt-3 mb-3"></div>
                                                            <div className="cart-total d-flex justify-between">
                                                                <span className="fw-500 fs-18 text-gray-500"><T>Total:</T></span>
                                                                <span className="fw-500 fs-18 text-secondary">SAR {cartSummary.TotalAmount.toFixed(2)}</span>
                                                            </div>
                                                            <div className="border-dotted-light mt-3 mb-3"></div>
                                                            {
                                                                voucher && voucher.length ?
                                                                <div className="cart-voucher mb-0 pb-0">
                                                                    <label><T>Coupons</T></label>
                                                                    <div className="applied-coupons mb-0">
                                                                        {
                                                                            voucher.map((coupon,key)=>{
                                                                                return coupon.RequireCouponCode ? <span key={key}> <i className="ri-close-line mr-2" onClick={()=>{removeCoupon(coupon.CouponCode)}}></i> <T>Code Applied:</T> "{coupon.CouponCode}"</span> : <i key={key} className="d-none"></i>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div> : <></> 
                                                            }
                                                            <div className="cart-voucher">
                                                                <label><T>Apply Coupon</T></label>
                                                                <div className="modal-content">
                                                                    <input type={"text"} onChange={(e)=>{setCouponCode(e.target.value)}} value={couponCode} placeholder={_T("Enter Code Here")} name="couponCode" id="couponCode"/>
                                                                    {
                                                                        loadingCoupon ?
                                                                        <button className="btn btn-primary" type="button"><T>Wait</T></button> :
                                                                        <button className="btn btn-primary" onClick={validateCouponSubmit} type="buton"><T>Apply</T></button>
                                                                    }
                                                                </div>
                                                                {couponError ? <span className="text-primary fs-12 fw-400">{couponError}</span> : null}
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="checkout-consent text-center d-flex align-items-center justify-center mt-0 mb-3">
                                                        <input name="checkoutConsent" id="checkoutConsent" className="input-checkbox" type={"checkbox"} checked={conditionChecked} onChange={(e)=>{setConditionChecked(e.target.checked)}}/><label htmlFor="checkoutConsent" className="fs-14 fw-400 text-gray-500"><span><T>I agree to the</T><Link to={"/terms-of-use"} className="text-primary decoration-none mx-1"><T>Terms of Service</T></Link> <T>and</T> <Link to='/privacy-policy' className="text-primary decoration-none mx-1"> <T>Privacy Policy</T> </Link></span></label>
                                                    </div>
                                                    <div className="checkout-btn">
                                                        { generateCheckoutOption() }
                                                        { outOfStock || isNotAvailable ? <p className="text-primary fs-14 fw-500 m-0 text-center mt-1">{ outOfStock ? <T>One or more products are out of stock</T>: <T>An occasion item was already purchased.</T> }</p> : <></> }
                                                    </div>
                                                    <div className="text-center d-flex align-items-center justify-center text-gray-300 fw-400 fs-14 mt-3"><i className="ri-lock-2-fill mr-2"></i><T>Payments are secure and encrypted</T></div>
                                                </div>
                                            </form>
                                    }
                                </div>
                                <div id="PopupCVV" className={popupCVV ? "active" : ""}>
                                    <div className="popupCVV-overlay" onClick={()=>{setPopupCVV(false)}}></div>
                                    <div className="popupCVV-container">
                                        <h2><T>Card CVV/CVC</T> <span className="text-primary">*</span></h2>
                                        <p><T>Please provide your card CVV/CVC to proceed with your payment.</T></p>
                                        {
                                            previousCard ?
                                            <div className="d-flex align-items-center popupCVV-card-container mb-3">
                                                <LazyLoadImage className="card-icon" src={cardIcons[previousCard.Scheme.toLowerCase().replaceAll(" ", "-")] ? cardIcons[previousCard.Scheme.toLowerCase().replaceAll(" ", "-")] : cardIcons["undefined"]} alt="" />
                                                <div>
                                                    <h3 className="fs-16 fw-500 text-gray-500 m-0">{previousCard.Scheme}  ****  {previousCard.Last4}</h3>
                                                    <p className="text-gray-400 fw-400 fs-14 m-0"><T>Expires on </T><span className="d-inline-flex">{previousCard.ExpiryMonth}</span><span className="d-inline-flex">/</span><span className="d-inline-flex">{previousCard.ExpiryYear.toString().slice(-2)}</span></p>
                                                </div>
                                                <input className="cvv-input" placeholder="CVV" value={previousCardCVV} onChange={(e)=>{previousCardCVVChange(e,previousCard.Scheme.toLowerCase().replaceAll(" ", "-") === "american-express" ? 4 : 3)}} type={'text'}/>
                                            </div> : <></>
                                        }
                                        {
                                            showPreviousCardCVVValid && !isPreviousCardCVVValid ?
                                            <span className="cvv-error d-flex align-items-center text-primary fs-12 fw-500 mt-2 mb-3"><i className="ri-information-line mr-1 fs-16"></i><T>Card CVV/CVC not valid</T></span> : <></>
                                        }
                                        {
                                            previousCard && isPreviousCardCVVValid ?
                                            <button className="btn btn-primary w-100" type="button" onClick={()=>{ confirmCVVCheckout({ cardId: previousCard.CardId , cvv: previousCardCVV }) }}><T>Finish Checkout</T></button> :
                                            <button className="btn btn-disabled w-100" type="button"><T>Finish Checkout</T></button>
                                        }
                                    </div>
                                </div>
                            </section> :
                            <section id="checkout">
                                <div className="wrapper">
                                    <div className="payment-loading text-center">
                                        <div className="payment-loading-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                                        <h2 className="text-gray-500 fw-500 fs-24 mt-4 mb-0"><T>Please Wait...</T></h2>
                                        <p className="text-gray-400 fw-400 fs-16 mt-0 mb-0"><T>Please wait while your order is being processed</T></p>
                                    </div>
                                </div>
                            </section> :
                        apiResponseStatus ?
                            <section id="checkout">
                                <div className="wrapper">
                                    <div className="checkout-order-details text-center">
                                        <h1 className="text-primary fw-500 fs-24 mb-0"><T>Thank you for Shopping</T></h1>
                                        <h2 className="text-gray-500 fw-500 fs-16"><T>Here are your order details</T></h2>
                                        <div className="mb-2">
                                            <span className="text-gray-400 fw-400 fs-16 d-block mt-0"><T>Order No</T></span>
                                            <span className="text-primary fw-500 fs-16">{apiResponse.OrderNumber}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="text-gray-400 fw-400 fs-16 d-block"><T>Total Amount</T></span>
                                            <span className="text-primary fw-500 fs-16"><span className="text-gray-500">SAR</span> {apiResponse.OrderAmount}</span>
                                        </div>
                                        <div className="mb-2">
                                            <span className="text-gray-400 fw-400 fs-16 d-block"><T>Payment Method</T></span>
                                            <span className="text-primary fw-500 fs-16">{apiResponse.PaymentType}</span>
                                        </div>
                                        {
                                            addressUnknown ? 
                                            <>
                                                <div className="mb-4 mt-4 divider-dotted"></div>
                                                <div className="mb-5">
                                                    <span className="d-block w-100 text-primary fw-500 fs-16 mb-1"><T>Shipping Address</T><span className="text-gray-400 fw-400 fs-16 d-block"><T>We will contact the recipient for the address</T></span></span>
                                                </div>
                                            </> :
                                            <>
                                                <div className="mb-4 mt-4 divider-dotted"></div>
                                                <div className="mb-2">
                                                    <span className="d-block w-100 text-primary fw-500 fs-16 mb-1"><T>Shipping Address</T><span className="text-gray-400 fw-400 fs-16 d-block">{apiResponse.ShippingAddress}</span></span>

                                                </div>
                                                <div className="mb-5">
                                                    <span className="d-block w-100 text-primary fw-500 fs-16 mb-1"><T>Billing Address</T><span className="text-gray-400 fw-400 fs-16 d-block">{apiResponse.BillingAddress}</span></span>
                                                </div>
                                            </>
                                        }
                                        <div className="d-flex align-items-center flex-wrap justify-center">
                                            <Link className="btn btn-primary mr-2" to="/products"><T>Continue Shopping</T></Link>
                                            <Link className="btn btn-outline-secondary" to={'/account/orders/' + apiResponse.Id}><T>Track Order</T></Link>
                                        </div>
                                    </div>
                                </div>
                            </section> :
                            <section id="checkout">
                                <div className="wrapper">
                                    <div className="checkout-order-details text-center">
                                        <LazyLoadImage src={failedIcon} width="100" alt="Payment Failed"/>
                                        <h1 className="text-primary fw-500 fs-24"><T>Order Failed!</T></h1>
                                        <p className="text-gray-400 fw-400 fs-16">{apiResponse[language] ? apiResponse[language] : <T>We apologize for the inconvenience but your order was canceled because your payment could not be processed. Please try again!</T>}</p>
                                        <button className="btn btn-primary mt-2" type="button" onClick={()=>{ window.location.reload(); }}><T>Try Again</T></button>
                                    </div>
                                </div>
                            </section>
                : <></>

            }
        </>
    )
}

export default CheckoutPage;
const translations = {
    "Your Order": {
      "en": "Your Order",
      "ar": "الطلب"
    },
    "Below is a history of your purchased items.": {
      "en": "Below is a history of your purchased items.",
      "ar": ".أدناه هو تاريخ العناصر التي اشتريتها"
    },
    "All": {
      "en": "All",
      "ar": "الكل"
    },
    "Pending": {
      "en": "Pending",
      "ar": "قيد الانتظار"
    },
    "Received": {
      "en": "Received",
      "ar": "تلقى"
    },
    "Processing": {
      "en": "Processing",
      "ar": "قيد التحضير"
    },
    "Shipped": {
      "en": "Shipped",
      "ar": "تم شحنه"
    },
    "Delivered": {
      "en": "Delivered",
      "ar": "تم التوصيل"
    },
    "Cancelled": {
      "en": "Cancelled",
      "ar": "تم إلغاءه"
    },
    "Order": {
      "en": "Order",
      "ar": "الطلب"
    },
    "Placed On": {
      "en": "Placed On",
      "ar": "تم انشاءه"
    },
    "Status": {
      "en": "Status",
      "ar": "الحالة"
    },
    "Estimated": {
      "en": "Estimated",
      "ar": "الوقت المتوقع"
    },
    "Price": {
      "en": "Price",
      "ar": "السعر"
    },
    "Quantity": {
      "en": "Quantity",
      "ar": "الكمية"
    },
    "Sub Total": {
      "en": "Sub Total",
      "ar": "المجموع الفرعي"
    },
    "View Details": {
      "en": "View Details",
      "ar": "عرض التفاصيل"
    },
    "Fetching Order data. Please wait....": {
      "en": "Fetching Order data. Please wait....",
      "ar": "تحميل بيانات الطلب. أرجو الإنتظار..."
    },
    "We could not find the order you were looking for.": {
      "en": "We could not find the order you were looking for.",
      "ar": "لم نتمكن من العثور على الطلب الذي كنت تبحث عنه."
    },
    "You currently have no orders": {
      "en": "You currently have no orders",
      "ar": "ليس لديك طلبات حاليا"
    },
    "You currently have no pending orders": {
      "en": "You currently have no pending orders",
      "ar": "ليس لديك طلبات معلقة حاليا"
    },
    "You currently have no orders in process": {
      "en": "You currently have no orders in process",
      "ar": "ليس لديك طلبات تحت المعالجة حاليا"
    },
    "You currently have no orders that are already shipped": {
      "en": "You currently have no orders that are already shipped",
      "ar": "ليس لديك أي طلبات تم شحنها حاليا "
    },
    "You currently have no orders that are complete": {
      "en": "You currently have no orders that are complete",
      "ar": "ليس لديك أي طلبات مكتملة حاليا"
    },
    "You currently have no orders that are cancelled": {
      "en": "You currently have no orders that are cancelled",
      "ar": "ليس لديك أي طلبات تم إلغاؤها حاليا"
    },
    "Your order invoice has been sent to your registered email address.": {
      "en": "Your order invoice has been sent to your registered email address.",
      "ar": "تم إرسال فاتورة طلبك إلى عنوان بريدك الإلكتروني المسجل."
    },
    "Request Invoice": {
      "en": "Request Invoice",
      "ar": "طلب الفاتورة"
    },
    "is": {
      "en": "is",
      "ar": "هو"
    },
    "Shipping & Billing Address": {
      "en": "Shipping & Billing Address",
      "ar": "عنوان الشحن والفواتير"
    },
    "Shipping Address": {
      "en": "Shipping Address",
      "ar": "عنوان الشحن"
    },
    "Billing Address": {
      "en": "Billing Address",
      "ar": "عنوان وصول الفواتير"
    },
    "Total Summary": {
      "en": "Total Summary",
      "ar": "الملخص"
    },
    "Discount": {
      "en": "Discount",
      "ar": "الخصم"
    },
    "Shipping": {
      "en": "Shipping",
      "ar": "الشحن"
    },
    "Order Total": {
      "en": "Order Total",
      "ar": "مجموع الطلب"
    },
    "Coupons": {
      "en": "Coupons",
      "ar": "قسيمة الخصم"
    },
    "Free": {
      "en": "Free",
      "ar": "مجانًا"
    },
    "Payment Information": {
      "en": "Payment Information",
      "ar": "معلومات الدفع"
    },
    "The order was placed using": {
      "en": "The order was placed using",
      "ar": "تم وضع الطلب باستخدام"
    },
    "Cash on Delivery": {
      "en": "Cash on Delivery",
      "ar": "الدفع عند الاستلام"
    },
    "Debit/Credit Card": {
      "en": "Debit/Credit Card",
      "ar": "بطاقة الخصم / الائتمان"
    },
    "Payment Status": {
      "en": "Payment Status",
      "ar": "حالة السداد"
    },
    "Paid": {
      "en": "Paid",
      "ar": "مدفوع"
    },
    "Refunded": {
      "en": "Refunded",
      "ar": "استرداد"
    },
    "Order Items": {
      "en": "Order Items",
      "ar": "منتجات الطلب"
    },
    "Wrap Details": {
      "en": "Wrap Details",
      "ar": "عرض تفاصيل الغلاف"
    },
    "Product has a Gift Wrap": {
      "en": "Product has a Gift Wrap",
      "ar": "المنتج يحتوي على غلاف هدية"
    },
    "Gift card attached": {
      "en": "Gift card attached",
      "ar": "بطاقة الهدايا مرفقة"
    },
    "To: ": {
      "en": "To: ",
      "ar": "إلى:"
    },
    "From: ": {
      "en": "From: ",
      "ar": "من:"
    },
    "Message: ": {
      "en": "Message: ",
      "ar": "رسالة:"
    },
    "Close": {
      "en": "Close",
      "ar": "اغلاق"
    },
    "Cancel Reason": {
      "en": "Cancel Reason",
      "ar": "سبب الإلغاء"
    },
    "Cancel Order": {
      "en": "Cancel Order",
      "ar": "إلغاء الطلب"
    },
    "Reason for Order Cancel": {
      "en": "Reason for Order Cancel",
      "ar": "سبب إلغاء الطلب"
    },
    "Reason for Order Return": {
      "en": "Reason for Order Return",
      "ar": "سبب إرجاع الطلب"
    },
    "Confirm": {
      "en": "Confirm",
      "ar": "تأكيد"
    },
    "You must select a reason to cancel your order.": {
      "en": "You must select a reason to cancel your order.",
      "ar": "يجب عليك تحديد سبب لإلغاء طلبك."
    },
    "Your order could not be cancelled at the moment.": {
      "en": "Your order could not be cancelled at the moment.",
      "ar": "لا يمكن إلغاء طلبك في الوقت الحالي."
    },
    "You must select a reason to return your order.": {
      "en": "You must select a reason to return your order.",
      "ar": "يجب عليك تحديد سبب لإعادة طلبك."
    },
    "Your order could not be returned at the moment.": {
      "en": "Your order could not be returned at the moment.",
      "ar": "لا يمكن إرجاع طلبك في الوقت الحالي."
    },
    "Please select a valid quantity.": {
      "en": "Please select a valid quantity.",
      "ar": "الرجاء تحديد كمية صالحة."
    },
    "I agree to the": {
      "en": "I agree to the",
      "ar": "أنا أوافق على"
    },
    "Return Policy": {
      "en": "Return Policy",
      "ar": "سياسة الاسترجاع"
    },
    "and": {
      "en": "and",
      "ar": "و"
    },
    "Privacy Policy": {
      "en": "Privacy Policy",
      "ar": "سياسة الخصوصية"
    },
    "Return Item": {
      "en": "Return Item",
      "ar": "إرجاع منتج"
    },
    "Return Requested": {
      "en": "Return Requested",
      "ar": "طلب الإرجاع"
    },
    "Return Approved": {
      "en": "Return Approved",
      "ar": "تمت الموافقة على الإسترجاع"
    },
    "Return Declined": {
      "en": "Return Declined",
      "ar": "تم رفض الاسترجاع"
    },
    "Please Wait": {
      "en": "Please Wait",
      "ar": "انتظر من فضلك"
    },
    "Return Reason:": {
      "en": "Return Reason:",
      "ar": "سبب الإسترجاع:"
    },
    "Return Decline Reason:": {
      "en": "Return Decline Reason:",
      "ar": "سبب رفض الإسترجاع:"
    },
    "Return Pickup Date:": {
      "en": "Return Pickup Date:",
      "ar": "تاريخ الاستلام المرتجع:"
    },
    "Item": {
      "en": "Item",
      "ar": "منتج"
    },
    "Items": {
      "en": "Items",
      "ar": "منتجات"
    },
    "Saved:": {
      "en": "Saved:",
      "ar": "حفظ"
    },
    "Inclusive of VAT": {
      "en": "Inclusive of VAT",
      "ar": "شامل ضريبة القيمة المضافة"
    },
    "Tax (VAT)": {
      "en": "Tax (VAT)",
      "ar": "ضريبة القيمة المضافة:"
    },
    "Returnable by": {
      "en": "Returnable by",
      "ar": "يمكن إرجاعه بواسطة"
    },
    "Partially Delivered Processing": {
      "en": "Partially Delivered Processing",
      "ar": "تسليم جزئي للمعالجة"
    },
    "Partially Delivered": {
      "en": "Partially Delivered",
      "ar": "سلمت جزئيا"
    },
    "Return Quantity": {
      "en": "Return Quantity",
      "ar": "الكمية المسترجعة"
    },
    "Order Options": {
      "en": "Order Options",
      "ar": "خيارات الطلب"
    },
    "Return Items": {
      "en": "Return Items",
      "ar": "إرجاع المنتجات"
    },
    "Select Return Items": {
      "en": "Select Return Items",
      "ar": "اختر إرجاع المنتجات"
    },
    "Please wait while your request is submitting.": {
      "en": "Please wait while your request is submitting.",
      "ar": "يرجى الانتظار بينما يتم تقديم طلبك."
    },
    "Items should have all the accessories with the original box": {
      "en": "Items should have all the accessories with the original box",
      "ar": "يجب أن تحتوي المنتجات على جميع الملحقات مع الصندوق الأصلي"
    },
    "Image": {
      "en": "Image",
      "ar": "صورة"
    },
    "Reason": {
      "en": "Reason",
      "ar": "السبب"
    },
    "Return Reason": {
      "en": "Return Reason",
      "ar": "سبب الإسترجاع"
    },
    "Request Return": {
      "en": "Request Return",
      "ar": "طلب إسترجاع"
    },
    "Reason to return": {
      "en": "Reason to return",
      "ar": "سبب الإسترجاع"
    },
    "Continue": {
      "en": "Continue",
      "ar": "اكمل"
    },
    "Upload an Image": {
      "en": "Upload an Image",
      "ar": "تحميل صورة"
    },
    "Browse": {
      "en": "Browse",
      "ar": "تصفح"
    },
    "Back": {
      "en": "Back",
      "ar": "الرجوع"
    },
    "Finish": {
      "en": "Finish",
      "ar": "إنهاء"
    },
    "Return Request Initiated": {
      "en": "Return Request Initiated",
      "ar": "تم إنشاء طلب الإسترجاع"
    },
    "Your request to return items has been received. We will review your request and inform you within one working day.": {
      "en": "Your request to return items has been received. We will review your request and inform you within one working day."
    },
    "View Return Order": {
      "en": "View Return Order",
      "ar": "عرض طلب الإسترجاع"
    },
    "Back to Home": {
      "en": "Back to Home",
      "ar": "العودة الى الصفحة الرئيسية"
    },
    "There is an error while submitting yor request. Please try again.": {
      "en": "There is an error while submitting yor request. Please try again.",
      "ar": ".هناك خطأ أثناء تقديم طلبك. حاول مرة اخرى"
    },
    "Please select valid quantity for the returns": {
      "en": "Please select valid quantity for the returns",
      "ar": "الرجاء تحديد كمية صالحة للإرجاع"
    },
    "Please select valid reason for the returns": {
      "en": "Please select valid reason for the returns",
      "ar": "الرجاء تحديد سبب صالح للإرجاع"
    },
    "Please select one or more product(s) to proceed.": {
      "en": "Please select one or more product(s) to proceed.",
      "ar": ".الرجاء تحديد منتج واحد أو أكثر للمتابعة"
    },
    "Click here to read our": {
      "en": "Click here to read our",
      "ar": "انقر هنا لقراءة"
    },
    "How to": {
      "en": "How to",
      "ar": "كيف"
    },
    "View Shipment": {
      "en": "View Shipment",
      "ar": "عرض الشحنة"
    },
    "Shipment": {
      "en": "Shipment",
      "ar": "الشحنة"
    },
    "Below is a history of your order items.": {
      "en": "Below is a history of your order items.",
      "ar": ".يوجد أدناه سجل لمنتجات طلبك"
    },
    "Please wait while the shipments are loading...": {
      "en": "Please wait while the shipments are loading...",
      "ar": "يرجى الانتظار حتى يتم تحميل الشحنات..."
    },
    "Shipments": {
      "en": "Shipments",
      "ar": "الشحنات"
    },
    "Shipment": {
      "en": "Shipment",
      "ar": "شحنة"
    },
    "Pending Items": {
      "en": "Pending Items",
      "ar": "المنتجات المعلقة"
    },
    "Track Shipment": {
      "en": "Track Shipment",
      "ar": "مسار الشحنة"
    },
    "Tracking Number": {
      "en": "Tracking Number",
      "ar": "رقم التتبع"
    },
    "Status": {
      "en": "Status",
      "ar": "الحالة"
    },
    "Courier Name": {
      "en": "Courier Name",
      "ar": "اسم البريد"
    },
    "Qty": {
      "en": "Qty",
      "ar": "الكمية"
    },
    "No Shipment Found": {
      "en": "No Shipment Found",
      "ar": "لم يتم العثور على الشحنة"
    },
    "No Pending Items Found": {
      "en": "No Pending Items Found",
      "ar": "لم يتم العثور على عناصر معلقة"
    },
    "Order Shipment": {
      "en": "Order Shipment",
      "ar": "أمر الشحن"
    },
    "Price": {
      "en": "Price",
      "ar": "السعر"
    },
    "Sub Total": {
      "en": "Sub Total",
      "ar": "المجموع الفرعي"
    },
    "This item has been removed from your order": {
      "en": "This item has been removed from your order",
      "ar": "تمت إزالة هذا المنتج من طلبك"
    },
    "is refunded to you": {
      "en": "is refunded to you",
      "ar": "تم استرجاعها"
    },
    "Expected Delivery": {
      "en": "Expected Delivery",
      "ar": "وقت التوصيل المتوقع"
    },
    "Delivered": {
      "en": "Delivered",
      "ar": "تم التوصيل"
    },
    "This item will be shipped later. Estimated delivery date": {
      "en": "This item will be shipped later. Estimated delivery date",
      "ar": "سيتم شحن هذا المنتج لاحقًا ، تاريخ التسليم المقدر"
    },
    "This item is Returnable": {
      "en": "This item is Returnable",
      "ar": "هذا المنتج قابل للإرجاع"
    },
    "This item is Not Returnable": {
      "en": "This item is Not Returnable",
      "ar": "هذا المنتج غير قابل للإرجاع"
    }
  } 

  export default translations
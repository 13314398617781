import { Link } from "react-router-dom"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import popupImage from "../../assets/images/popup-icon.png"
import popupWishlistImage from "../../assets/images/popup-wishlist-icon.png"
import "../../assets/css/--component-product-card.css"
import { useState, useEffect, useContext } from "react";
import { addCart, addWishlist } from "../../helper/backend-methods";
import noImage from "../../assets/images/no-image.jpg"
import warningIcon from "../../assets/images/failed-icon.png"
import { analyticsTrackEvent } from "../../components/Analytics"
import _NC from "../../components/Numbers"
import { GlobalContext } from "../../context/index";
import T from "../../locale/translations-main"
import { LazyLoadImage } from "react-lazy-load-image-component"


function ProductCard({item, pageTitle, permissions}){
    const [ occasionId, setOccasionId] = useState(0);
    const { cartCount, updateCartCount } = useContext(GlobalContext);
    const [popup, setPopup] = useState(false)
    const [props, setProps] = useState(null)
    const [authorized, setAuthorized] = useState(false);
    const [IsWishlist, setIsWishlist] = useState(false)
    const [popupWishlist, setPopupWishlist] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)
    const [cartHasItems, setCartHasItems] = useState(localStorage.getItem("cartItemCount"))
    const [popupConflict, setPopupConflict] = useState(false)


    useEffect(() => {
        const occasion = localStorage.getItem("occasion") ? parseInt(localStorage.getItem("occasion")) : 0
        setOccasionId(occasion)
        setProps(item)
        setIsWishlist(item.IsWishlist)
        setLoading(false)
        const accessToken = localStorage.getItem("authToken")
        const isAuthenticated = accessToken && accessToken.length ? true : false;
        setAuthorized(isAuthenticated)
    },[item])


    const addToCart = (product) => {
        const occasion = localStorage.getItem("occasion") ? parseInt(localStorage.getItem("occasion")) : 0
        setOccasionId(occasion)
        setLoadingButton(true)
        // if cart is currently empty
        if(!cartHasItems){
            addCart({Quantity:1, ProductId : [props.ProductId]}).then(({data}) =>{
                if(data.Status){
                    analyticsTrackEvent("Added to Cart", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName, sku: props.ProductSku, price: props.Price, quantity: 1 },permissions && permissions.tracking)
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",0)
                    localStorage.setItem("cartItemCount",1);
                    setPopup(true)
                }
                setLoadingButton(false)
            }).catch((e)=>{
                console.log(e);
                setLoadingButton(false)
            })
        }
        // if items already in cart don't belong to any occasion
        else if(occasion === 0){
            addCart({Quantity:1, ProductId : [props.ProductId]}).then(({data}) =>{
                if(data.Status){
                    analyticsTrackEvent("Added to Cart", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName, sku: props.ProductSku, price: props.Price, quantity: 1 },permissions && permissions.tracking)
                    updateCartCount(data.Data.CartItemCount);
                    localStorage.setItem("occasion",0)
                    localStorage.setItem("cartItemCount",1);
                    setOccasionId(0)
                    setPopup(true)
                }
                setLoadingButton(false)
            }).catch((e)=>{
                console.log(e);
                setLoadingButton(false)
            })
        }
        // if items already in cart belong to an occasion
        else{
            setPopupConflict(true);
            setLoadingButton(false)
        }
    }
    const addToCartAfterConfirmation = () => {
        setPopupConflict(false);
        addCart({Quantity:1, ProductId : [props.ProductId]}).then(({data}) =>{
            if(data.Status){
                analyticsTrackEvent("Added to Cart", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName, sku: props.ProductSku, price: props.Price, quantity: 1 },permissions && permissions.tracking)
                updateCartCount(data.Data.CartItemCount);
                localStorage.setItem("occasion",0)
                localStorage.setItem("cartItemCount",1);
                setOccasionId(0)
                setPopup(true)
            }
            setLoadingButton(false)
        }).catch((e)=>{
            console.log(e);
            setLoadingButton(false)
        })
    }



    const addUpdateWishlist = () => {
        addWishlist({ ProductId: props.ProductId, Add: !IsWishlist }).then(({data}) => {
            if(data.Status){
                setIsWishlist(!IsWishlist)
                if (!IsWishlist) {
                    setPopupWishlist(true)
                    analyticsTrackEvent("Wish List Item Added", { screen_name: pageTitle, product_id: props.ProductId, product_name: props.ProductName, brand: props.ManufacturerName, category: props.CategoryName },permissions && permissions.tracking)
                }
            }
           
        }).catch((e) => {
            console.log(e)
        })
    }
      
    return (
        <div className="product-card">
            {
                loading ? <Skeleton height="460px" style={{width: "100%"}} /> :
                <>
                    {props.DiscountPercentage ? <span className="product-tag">{props.DiscountPercentage}% <T>Off</T></span> : <></>}
                    <div className="product-header">
                        <Link to={`/product/${props.ProductId}`} className="product-image">
                            <LazyLoadImage src={ props.ProductPictures && props.ProductPictures.length ? props.ProductPictures[0].Url : noImage} onError={(e) => { e.target.onerror = null; e.target.src = noImage }} alt={props.ProductName} draggable={false} />
                        </Link>
                        { 
                        authorized ?
                        <button onClick={()=>{addUpdateWishlist()}} className={ IsWishlist ? "product-wishlist active " : "product-wishlist"}><svg xmlns="http://www.w3.org/2000/svg" width="20.538" height="19.536" viewBox="0 0 20.538 19.536"><g transform="translate(0.769 0.786)"><g transform="translate(0 0)"><path d="M9.261,17.854a34.84,34.84,0,0,1-6.022-4.689A11.99,11.99,0,0,1,.373,8.6C-.7,5.25.554,1.421,4.071.288A5.978,5.978,0,0,1,9.5,1.2h0A5.987,5.987,0,0,1,14.921.288C18.438,1.421,19.7,5.25,18.628,8.6a11.99,11.99,0,0,1-2.866,4.57A34.84,34.84,0,0,1,9.74,17.854L9.505,18Z" transform="translate(0 0)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0A2.782,2.782,0,0,1,1.917,2.422" transform="translate(13.239 4.053)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg></button> :
                        <Link to={"/login?redirect=/product/"+props.ProductId} className="product-wishlist"><svg xmlns="http://www.w3.org/2000/svg" width="20.538" height="19.536" viewBox="0 0 20.538 19.536"><g transform="translate(0.769 0.786)"><g transform="translate(0 0)"><path d="M9.261,17.854a34.84,34.84,0,0,1-6.022-4.689A11.99,11.99,0,0,1,.373,8.6C-.7,5.25.554,1.421,4.071.288A5.978,5.978,0,0,1,9.5,1.2h0A5.987,5.987,0,0,1,14.921.288C18.438,1.421,19.7,5.25,18.628,8.6a11.99,11.99,0,0,1-2.866,4.57A34.84,34.84,0,0,1,9.74,17.854L9.505,18Z" transform="translate(0 0)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/><path d="M0,0A2.782,2.782,0,0,1,1.917,2.422" transform="translate(13.239 4.053)" fill="none" stroke="#f37b31" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/></g></g></svg></Link> 
                        }
                        { props.ManufacturerName && props.ManufacturerPicture ? <span className="product-brand">
                            <LazyLoadImage src={props.ManufacturerPicture.Url} alt={props.ManufacturerName} draggable={false}/>
                        </span> : <></> }
                    </div>
                    <div className="product-body">
                        <Link to={`/product/${props.ProductId}`} className="product-title" title={props.ProductName}>{props.ProductName.length > 34 ? props.ProductName.slice(0, 31) + "..." : props.ProductName}</Link>
                        <div className="product-price">
                            <span className="product-currency">SAR</span>
                            <span className="product-amount">{props.UnitPriceAmount ? (props.Price - props.UnitPriceAmount).toFixed(2) : props.Price.toFixed(2)}</span>
                            { props.UnitPriceAmount ? <span className="product-old-amount"><s className="text-primary">SAR {props.Price.toFixed(2)}</s></span> : <></> }
                        </div>
                        <div className="product-info">
                            <div className="product-rating-stars">
                                {
                                    [0,0,0,0,0].map((i,v)=>{
                                        return v <= (Math.floor(item.Rating)-1) ? <i className="ri-star-fill fs-14" style={{color: "rgb(255, 179, 0)"}} key={v}></i> : <i className="ri-star-line fs-14" style={{color: "rgb(255, 179, 0)"}} key={v}></i>
                                    })
                                }
                            </div>
                            { props.StockManagement  ? props.StockManagement.InStock ? props.StockManagement.StockQuantity !== null && props.StockManagement.MinStockQuantity > props.StockManagement.StockQuantity ? <span className="fs-14 fw-500 text-primary mb-1 product-stock">{props.StockManagement.StockQuantity} <T>PCs left</T></span> : <span className="product-stock"><T>In Stock</T></span> : "" : <span className="product-stock"><T>In Stock</T></span> }
                        </div>
                        {
                            props.StockManagement  ? 
                            props.StockManagement.InStock ?
                            <button onClick={()=>{ loadingButton ? console.log("Loading") : addToCart(props)}} type="button" className="product-action btn btn-primary">{ loadingButton ? <T>Please Wait</T> : <T>Add to Cart</T>}</button> :
                            <button type="button" className="product-action btn btn-outline-primary-disabled"><T>Out of Stock</T></button> :
                            <button onClick={()=>{ loadingButton ? console.log("Loading") : addToCart(props)}} type="button" className="product-action btn btn-primary">{ loadingButton ? <T>Please Wait</T> : <T>Add to Cart</T>}</button>
                        }               
                    </div>
                    <div className={popup ? "product-card-popup active" : "product-card-popup"}>
                        <div className="popup-wrapper">
                            <h2 className="popup-title">{props.ProductName.length > 40 ? props.ProductName.slice(0, 37) + "..." : props.ProductName}</h2>
                            <span className="popup-subheading"><T>successfully added to cart</T></span>
                            <div className="popup-image-container">
                                <LazyLoadImage src={popupImage} alt="" />
                            </div>
                            <Link className="btn btn-primary" to="/cart"><T>Go to Cart</T></Link>
                            <button className="btn btn-outline-secondary" onClick={() => { setPopup(false) }}><T>Continue Shopping</T></button>
                        </div>
                    </div>
                    <div className={popupWishlist ? "product-card-popup active" : "product-card-popup"}>
                        <div className="popup-wrapper">
                            <h2 className="popup-title">{props.ProductName.length > 40 ? props.ProductName.slice(0, 37) + "..." : props.ProductName}</h2>
                            <span className="popup-subheading"><T>successfully added to your wishlist</T></span>
                            <div className="popup-image-container">
                                <LazyLoadImage src={popupWishlistImage} alt="" />
                            </div>
                            <Link className="btn btn-primary" to="/account/wishlist"><T>Go to Wishlist</T></Link>
                            <button className="btn btn-outline-secondary" onClick={() => { setPopupWishlist(false) }}><T>Continue Shopping</T> </button>
                        </div>
                    </div>
                    <div className={ popupConflict ? "product-card-popup active" : "product-card-popup"}>
                        <div className="popup-wrapper">
                            <div className="popup-image-container mb-1">
                                <LazyLoadImage src={warningIcon} alt="warning" style={{ height: "140px", width: "140px"}} />
                            </div>
                            {
                                <span className="popup-subheading text-gray-500 fs-14 mt-4 mb-4"><T>You already have items associated with an occasion in your cart and you are about to add a product that doesn't belong to any occasion. This will clear all your current cart items.</T></span>                                
                            }
                            <div className="d-flex align-items-center justify-center">
                                <button className="btn btn-outline-secondary ml-1 mr-1 mb-2" onClick={()=>{setPopupConflict(false)}}><T>Cancel</T></button>
                                <button className="btn btn-primary ml-1 mr-1 mb-2" onClick={()=>{addToCartAfterConfirmation(item.ProductId)}}><T>Continue</T></button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ProductCard;
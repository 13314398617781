import { useEffect, useState } from "react";
import BreadCrumbs from "../../components/BreadCrumbs";
import '../../assets/css/--page-categories.css'
import { analyticsPageEvent } from "../../components/Analytics";
import { getManufacturers } from "../../helper/backend-methods";
import Skeleton from "react-loading-skeleton";
import T from "../../locale/translations-main";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Brands(props) {
    const breadcrumbItems = [ { title: "Home", link: "/" }, { title: "Brands", link: "" } ];
    const [brands,setBrands] = useState([]);
    const [loading,setLoading] = useState(true);


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (props.consent.page) {
            analyticsPageEvent("Brands");
        }

        getManufacturers().then(({data})=>{
            if(data.Status){
                setBrands(data.Data)
            }
            setLoading(false)
        }).catch((e)=>{
            setLoading(false)
        })
    }, [])


    return (
        <>
            <BreadCrumbs pageTile="Brands" breadcrumbItems={breadcrumbItems} />
            <section id="brands" className="pt-4 pb-5">
                <div className="wrapper">
                    <T tagName={"h1"} className="fs-26 fw-600 text-gray-500 mt-4 mb-0">Brands</T>
                    <T tagName={"p"} className="fs-18 fw-400 text-gray-400 mt-0 mb-5">Find products based on a brand</T>
                    <div className="brand-grid">
                        {
                            loading ?
                            [...Array(40)].map((c,k)=>{
                                return <div className="brand-card" key={k}>
                                            <Skeleton height="150px" />
                                    </div>
                            }) :
                            brands.length ?
                            brands.map(brand => {
                                return (
                                    <Link to={`/products?brand=${brand.ManufacturerId}`} className="brand-card enabled" key={brand.ManufacturerId} draggable="false">
                                        <LazyLoadImage src={brand.ManufacturerPicture.Url} alt="" draggable="false"/>
                                        <h3 className="text-gray-500 fw-600 fs-16" draggable="false">{brand.ManufacturerName}</h3>
                                    </Link>
                                )
                            }) :
                            <button className="btn btn-alternative w-100"><T>There are currently no brands</T></button>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Brands